var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _vm.render
      ? _c(
          "div",
          { staticClass: "fileDownloadPanel" },
          [
            !_vm.viewList.length && _vm.manageMode
              ? [
                  _c(
                    "div",
                    { staticClass: "empty_tips_panel" },
                    [
                      _c("div", { staticClass: "text" }, [
                        _vm._v("请在右侧面板中添加文件"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button-component",
                        {
                          attrs: { active: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.addFile.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("添加文件")]
                      ),
                    ],
                    1
                  ),
                ]
              : !_vm.viewList.length && !_vm.manageMode
              ? [
                  _c("div", { staticClass: "cus_Empty_tips_panel" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v("没有添加文件"),
                    ]),
                  ]),
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "fileDownloadPanel newFileDownloadPanel" },
                    [
                      _vm._l(_vm.viewList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "mainBodyContainer tableBox",
                          },
                          [
                            _c("div", { staticClass: "fileImg tableCell" }, [
                              _c(
                                "svg",
                                {
                                  staticClass: "fileSvgClass",
                                  attrs: { role: "img" },
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href": _vm.transformSvg(item.name),
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.nameWrap
                              ? _c(
                                  "div",
                                  { staticClass: "fileName tableCell" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "fileNameText fileNameWrap g_file_name",
                                        style: _vm.file_name_style,
                                        attrs: { title: item.name },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fileSize g_file_size",
                                        style: _vm.file_size_style,
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.parseFileSize(item.size))
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "fileName tableCell" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fileNameText g_file_name",
                                        style: _vm.file_name_style,
                                        attrs: { title: item.name },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fileSize g_file_size",
                                        style: _vm.file_size_style,
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.parseFileSize(item.size))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                            _vm._v(" "),
                            /.pdf$/.test(item.name) &&
                            !_vm.module.content.isAndroid
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "fileViewWrap",
                                    attrs: { title: item.name },
                                    on: { click: _vm.logViewFile },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "fileViewIcon",
                                        attrs: {
                                          target: "_blank",
                                          href: "javascript:void(0);",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkPreviewPdf(
                                              $event,
                                              item.sourcePath
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "jzm-vicon_preview downloadSvg previewSvg",
                                            style: _vm.downloadIconSize,
                                          },
                                          [
                                            _c("use", {
                                              style: _vm.downloadPreviewColor,
                                              attrs: {
                                                "xlink:href":
                                                  "#jzm-vicon_preview",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.allowedMemberDownload
                              ? [
                                  _vm.showMemberLevel == 1
                                    ? [
                                        _vm.sessionMid <= 0
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fileOperate tableCell",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      class:
                                                        "fileDownload" + _vm.id,
                                                      attrs: {
                                                        id: _vm.pinModuleId(
                                                          index
                                                        ),
                                                        target: "_self",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.jumptoIndex(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "jzm-v16 downloadSvg",
                                                          style:
                                                            _vm.downloadIconSize,
                                                          attrs: {
                                                            role: "img",
                                                          },
                                                        },
                                                        [
                                                          _c("use", {
                                                            staticStyle: {
                                                              fill: "#666666",
                                                            },
                                                            style:
                                                              _vm.downloadIconColor,
                                                            attrs: {
                                                              "xlink:href":
                                                                "#jzm-v16",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : [
                                              (_vm.visitorMemberLevel <
                                                _vm.memberLevelLimit &&
                                                !_vm.onlyLevel) ||
                                              (_vm.onlyLevel &&
                                                _vm.visitorMemberLevel !=
                                                  _vm.memberLevelLimit)
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fileOperate tableCell",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          class:
                                                            "fileDownload" +
                                                            _vm.id,
                                                          attrs: {
                                                            id: _vm.pinModuleId(
                                                              index
                                                            ),
                                                            target: "_self",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.levelLimit,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "jzm-v16 downloadSvg",
                                                              style:
                                                                _vm.downloadIconSize,
                                                              attrs: {
                                                                role: "img",
                                                              },
                                                            },
                                                            [
                                                              _c("use", {
                                                                staticStyle: {
                                                                  fill: "#666666",
                                                                },
                                                                style:
                                                                  _vm.downloadIconColor,
                                                                attrs: {
                                                                  "xlink:href":
                                                                    "#jzm-v16",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fileOperate tableCell",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          class:
                                                            "fileDownload" +
                                                            _vm.id,
                                                          attrs: {
                                                            id: _vm.pinModuleId(
                                                              index
                                                            ),
                                                            target: "_blank",
                                                            href: item.downloadUrl,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "jzm-v16 downloadSvg",
                                                              style:
                                                                _vm.downloadIconSize,
                                                              attrs: {
                                                                role: "img",
                                                              },
                                                            },
                                                            [
                                                              _c("use", {
                                                                staticStyle: {
                                                                  fill: "#666666",
                                                                },
                                                                style:
                                                                  _vm.downloadIconColor,
                                                                attrs: {
                                                                  "xlink:href":
                                                                    "#jzm-v16",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                      ]
                                    : _vm.showMemberLevel == 2
                                    ? [
                                        _vm.sessionMid <= 0
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fileOperate tableCell",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      class:
                                                        "fileDownload" + _vm.id,
                                                      attrs: {
                                                        id: _vm.pinModuleId(
                                                          index
                                                        ),
                                                        target: "_self",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.jumptoIndex(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "jzm-v16 downloadSvg",
                                                          style:
                                                            _vm.downloadIconSize,
                                                          attrs: {
                                                            role: "img",
                                                          },
                                                        },
                                                        [
                                                          _c("use", {
                                                            staticStyle: {
                                                              fill: "#666666",
                                                            },
                                                            style:
                                                              _vm.downloadIconColor,
                                                            attrs: {
                                                              "xlink:href":
                                                                "#jzm-v16",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : [
                                              !_vm.isFileMemberGroup
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fileOperate tableCell",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          class:
                                                            "fileDownload" +
                                                            _vm.id,
                                                          attrs: {
                                                            id: _vm.pinModuleId(
                                                              index
                                                            ),
                                                            target: "_self",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.groupLimit,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "jzm-v16 downloadSvg",
                                                              style:
                                                                _vm.downloadIconSize,
                                                              attrs: {
                                                                role: "img",
                                                              },
                                                            },
                                                            [
                                                              _c("use", {
                                                                staticStyle: {
                                                                  fill: "#666666",
                                                                },
                                                                style:
                                                                  _vm.downloadIconColor,
                                                                attrs: {
                                                                  "xlink:href":
                                                                    "#jzm-v16",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fileOperate tableCell",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          class:
                                                            "fileDownload" +
                                                            _vm.id,
                                                          attrs: {
                                                            id: _vm.pinModuleId(
                                                              index
                                                            ),
                                                            target: "_blank",
                                                            href: item.downloadUrl,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleDownload(
                                                                item,
                                                                $event
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "jzm-v16 downloadSvg",
                                                              style:
                                                                _vm.downloadIconSize,
                                                              attrs: {
                                                                role: "img",
                                                              },
                                                            },
                                                            [
                                                              _c("use", {
                                                                staticStyle: {
                                                                  fill: "#666666",
                                                                },
                                                                style:
                                                                  _vm.downloadIconColor,
                                                                attrs: {
                                                                  "xlink:href":
                                                                    "#jzm-v16",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "fileOperate tableCell",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                class: "fileDownload" + _vm.id,
                                                attrs: {
                                                  id: _vm.pinModuleId(index),
                                                  target: "_blank",
                                                  href: item.downloadUrl,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDownload(
                                                      item,
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass:
                                                      "jzm-v16 downloadSvg",
                                                    style: _vm.downloadIconSize,
                                                    attrs: { role: "img" },
                                                  },
                                                  [
                                                    _c("use", {
                                                      staticStyle: {
                                                        fill: "#666666",
                                                      },
                                                      style:
                                                        _vm.downloadIconColor,
                                                      attrs: {
                                                        "xlink:href":
                                                          "#jzm-v16",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                ]
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "fileOperate tableCell" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          class: "fileDownload" + _vm.id,
                                          attrs: {
                                            id: _vm.pinModuleId(index),
                                            target: "_blank",
                                            href: item.downloadUrl,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDownload(
                                                item,
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "jzm-v16 downloadSvg",
                                              style: _vm.downloadIconSize,
                                              attrs: { role: "img" },
                                            },
                                            [
                                              _c("use", {
                                                staticStyle: {
                                                  fill: "#666666",
                                                },
                                                style: _vm.downloadIconColor,
                                                attrs: {
                                                  "xlink:href": "#jzm-v16",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _vm.module.content.showPage
                        ? _c("pagenation", {
                            attrs: {
                              "module-id": _vm.id,
                              "total-size": _vm.totalSize,
                              "page-size": _vm.pageSize,
                              pageno: _vm.pageno,
                              "open-theme-v3": _vm.openThemeV3,
                              "ajax-pagenation": true,
                            },
                            on: { "on-change": _vm.changePage },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }