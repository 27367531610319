var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isNewAddModule
    ? _c(
        "div",
        {
          class: [
            _vm.paramCollectionWrapClass,
            _vm.contentBgClass,
            _vm.needPaddingWrapClass,
            _vm.priceStyle,
          ],
          style: _vm.paramCollectionWrapStyle,
        },
        [
          _vm.showProName
            ? _c("product-name", { attrs: { info: _vm.info } })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.list, function (item, index) {
            return [
              _vm.isShowPropsExceptPrice(item)
                ? _c(
                    "div",
                    { key: `prop_except_price_${index}` },
                    [
                      !_vm.showParamHasEdit ||
                      (_vm.showParamHasEdit && _vm.module.content.dp.sp) ||
                      (_vm.showParamHasEdit && !_vm.module.content.dp.sp)
                        ? [
                            _c(
                              "p",
                              {
                                key: "k_" + item.id,
                                staticClass:
                                  "g_link productParam proParamNoWrap",
                                class: [_vm.classes2, _vm.productParamterClass],
                                style: _vm.productParamter_style,
                              },
                              [
                                _vm.module.content.dp.sp
                                  ? [_vm._v(_vm._s(item.name) + " :")]
                                  : _vm._e(),
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.getValue(item)) +
                                    "\n                "
                                ),
                              ],
                              2
                            ),
                          ]
                        : _vm._isTwoCol && _vm.info[item.fieldKey] == ""
                        ? [
                            _c(
                              "p",
                              {
                                key: "k_" + item.id,
                                staticClass: "g_link productParam",
                                class: [
                                  _vm.otherPropClass,
                                  _vm.productParamterClass,
                                ],
                                style: _vm.productParamter_style,
                              },
                              [
                                _vm.module.content.dp.sp &&
                                _vm.module.content.proStyle != 3
                                  ? _c(
                                      "span",
                                      { staticClass: "productParamKey" },
                                      [_vm._v(_vm._s(item.name) + " :")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  "\n                     \n                "
                                ),
                              ]
                            ),
                          ]
                        : _c(
                            "p",
                            {
                              key: "k_" + item.id,
                              staticClass: "g_link productParam",
                              class: [
                                _vm.otherPropClass,
                                _vm.noMarginClass(item),
                                _vm.productParamterClass,
                              ],
                              style: _vm.productParamter_style,
                            },
                            [
                              _vm.module.content.dp.sp &&
                              _vm.module.content.proStyle != 3
                                ? _c(
                                    "span",
                                    { staticClass: "productParamKey" },
                                    [_vm._v(_vm._s(item.name) + " :")]
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.getValue(item)) +
                                  "\n            "
                              ),
                            ]
                          ),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          }),
          _vm._v(" "),
          _vm.isShowPriceWrap
            ? _c(
                "div",
                {
                  staticClass: "priceAndBtnWrap",
                  class: [
                    _vm.priceClass,
                    _vm.textAlignClass,
                    _vm.onlyHasPriceClass,
                  ],
                },
                [
                  !_vm.salePromotionOpen
                    ? _c(
                        "div",
                        { staticClass: "priceWrap", style: _vm.ct3AndPrice3 },
                        [
                          _vm._l(_vm.list, function (item, index) {
                            return [
                              item.type == _vm.SYS_MALL_PRICE && item.allowed
                                ? [
                                    _vm.showMallPrice
                                      ? [
                                          _c(
                                            "p",
                                            {
                                              key: `smp_${index}`,
                                              staticClass:
                                                "f-mallPriceWrap g_link productParam paramPrice",
                                              class: _vm.mallPriceWrapClass,
                                            },
                                            [
                                              _vm.module.content.dp.sp &&
                                              _vm.module.content.proStyle !=
                                                3 &&
                                              !_vm.hidePriceName
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "productParamKey g_productPrice",
                                                      style: _vm.price_style,
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.name) + " :"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "g_main_color_v3 mallPrice",
                                                  class: _vm.priceClass,
                                                },
                                                [
                                                  !_vm.siteCurrencyRight
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "priceChar g_productPrice",
                                                          style:
                                                            _vm.price_style,
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.module.extInfo
                                                                .choiceCurrencyVal
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "interger g_productPrice",
                                                      style: _vm.price_style,
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formateNewPrize(
                                                            _vm.info[
                                                              "actualPrice"
                                                            ],
                                                            true
                                                          )
                                                        )
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "dot g_productPrice",
                                                          style:
                                                            _vm.price_style,
                                                        },
                                                        [_vm._v(".")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "decimal g_productPrice",
                                                      style: _vm.price_style,
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formateNewPrize(
                                                            _vm.info[
                                                              "actualPrice"
                                                            ],
                                                            false
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.siteCurrencyRight
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "priceChar g_productPrice",
                                                          style:
                                                            _vm.price_style,
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.module.extInfo
                                                                .choiceCurrencyVal
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.info.vipName &&
                                              _vm.isHorizontalVip
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "vipName g_bgColor g_main_bgColor_v3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.info.vipName)
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                : (item.type == _vm.SYS_MALL_MARKET_PRICE ||
                                    item.type == _vm.SYS_PRICE) &&
                                  item.allowed
                                ? [
                                    _vm.showMarketPrice &&
                                    _vm.info[item.fieldKey] != -1
                                      ? _c(
                                          "p",
                                          {
                                            key: "k_" + item.id,
                                            staticClass:
                                              "f-priceWrap g_link productParam",
                                            class: _vm.marketPriceClass,
                                          },
                                          [
                                            _vm.module.content.dp.sp &&
                                            _vm.module.content.proStyle != 3 &&
                                            !_vm.hidePriceName
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "productParamKey g_productMallPrice",
                                                    style: _vm.mallPrice_style,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.name) + " :"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "marketPrice g_productMallPrice",
                                                style: _vm.mallPrice_style,
                                              },
                                              [
                                                _vm.siteCurrencyRight
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "g_productMallPrice deleteLine",
                                                        style:
                                                          _vm.mallPrice_style,
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatePrize(
                                                              _vm.info[
                                                                item.fieldKey
                                                              ]
                                                            )
                                                          ) +
                                                            _vm._s(
                                                              _vm.module.extInfo
                                                                .choiceCurrencyVal
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "g_productMallPrice deleteLine",
                                                        style:
                                                          _vm.mallPrice_style,
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.module.extInfo
                                                              .choiceCurrencyVal
                                                          ) +
                                                            _vm._s(
                                                              _vm.formatePrize(
                                                                _vm.info[
                                                                  item.fieldKey
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.list, function (item) {
                    return [
                      !_vm.showSalePromitionPrice &&
                      _vm._extInfo.isShowMarketPrice &&
                      (item.type == _vm.SYS_MALL_MARKET_PRICE ||
                        item.type == _vm.SYS_PRICE)
                        ? [
                            _c(
                              "p",
                              {
                                key: "k_" + item.id,
                                staticClass: "f-priceWrap g_link productParam",
                                class: _vm.marketPriceClass,
                              },
                              [
                                _vm.module.content.dp.sp &&
                                _vm.module.content.proStyle != 3
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "productParamKey g_productMallPrice",
                                        style: _vm.mallPrice_style,
                                      },
                                      [_vm._v(_vm._s(item.name) + " :")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "marketPrice g_productMallPrice",
                                    style: _vm.mallPrice_style,
                                  },
                                  [
                                    _vm.siteCurrencyRight
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "g_productMallPrice deleteLine",
                                            style: _vm.mallPrice_style,
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatePrize(
                                                  _vm.info[item.fieldKey]
                                                )
                                              ) +
                                                _vm._s(
                                                  _vm.module.extInfo
                                                    .choiceCurrencyVal
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass:
                                              "g_productMallPrice deleteLine",
                                            style: _vm.mallPrice_style,
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.module.extInfo
                                                  .choiceCurrencyVal
                                              ) +
                                                _vm._s(
                                                  _vm.formatePrize(
                                                    _vm.info[item.fieldKey]
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      item.type == _vm.SYS_SALEPROMOTIONPRICE
                        ? _c(
                            "div",
                            {
                              key: "ss_" + item.id,
                              staticClass: "salePromitionWrap",
                            },
                            [
                              item.type == _vm.SYS_SALEPROMOTIONPRICE &&
                              item.allowed &&
                              _vm.showSalePromitionPrice
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "salePromition" },
                                      [
                                        _vm.showSalePromitionPrice
                                          ? [
                                              _c(
                                                "p",
                                                {
                                                  key: "k_" + item.id,
                                                  staticClass:
                                                    "f-mallPriceWrap g_link productParam",
                                                  class: _vm.mallPriceWrapClass,
                                                },
                                                [
                                                  _vm.module.content.dp.sp &&
                                                  _vm.module.content.proStyle !=
                                                    3
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "productParamKey g_productPrice",
                                                          style:
                                                            _vm.price_style,
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.name) +
                                                              " :"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "g_main_color_v3 mallPrice promitionPrice",
                                                      staticStyle: {
                                                        "font-size": "0",
                                                      },
                                                    },
                                                    [
                                                      !_vm.siteCurrencyRight
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "priceChar g_productPrice",
                                                              style:
                                                                _vm.price_style,
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.module
                                                                    .extInfo
                                                                    .choiceCurrencyVal
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "interger g_productPrice",
                                                          style:
                                                            _vm.price_style,
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formateNewPrize(
                                                                _vm.info[
                                                                  item.fieldKey
                                                                ],
                                                                true
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "dot g_productPrice",
                                                          style:
                                                            _vm.price_style,
                                                        },
                                                        [_vm._v(".")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "decimal g_productPrice",
                                                          style:
                                                            _vm.price_style,
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formateNewPrize(
                                                                _vm.info[
                                                                  item.fieldKey
                                                                ],
                                                                false
                                                              )
                                                            ) + " "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.siteCurrencyRight
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "priceChar g_productPrice",
                                                              style:
                                                                _vm.price_style,
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.module
                                                                    .extInfo
                                                                    .choiceCurrencyVal
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.list,
                                                function (item3) {
                                                  return [
                                                    _vm._extInfo
                                                      .isShowMallPrice &&
                                                    item3.type ==
                                                      _vm.SYS_MALL_PRICE
                                                      ? [
                                                          _c(
                                                            "p",
                                                            {
                                                              key:
                                                                "kk_" +
                                                                item3.id,
                                                              staticClass:
                                                                "f-priceWrap g_link productParam",
                                                              class:
                                                                _vm.marketPriceClass,
                                                            },
                                                            [
                                                              _vm.module.content
                                                                .dp.sp &&
                                                              _vm.module.content
                                                                .proStyle != 3
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "productParamKey g_productMallPrice",
                                                                      style:
                                                                        _vm.mallPrice_style,
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item3.name
                                                                        ) + " :"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "marketPrice g_productMallPrice",
                                                                  style:
                                                                    _vm.mallPrice_style,
                                                                },
                                                                [
                                                                  _vm.siteCurrencyRight
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "g_productMallPrice deleteLine",
                                                                          style:
                                                                            _vm.mallPrice_style,
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.formatePrize(
                                                                                _vm
                                                                                  .info[
                                                                                  item3
                                                                                    .fieldKey
                                                                                ]
                                                                              )
                                                                            ) +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .module
                                                                                  .extInfo
                                                                                  .choiceCurrencyVal
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "g_productMallPrice deleteLine",
                                                                          style:
                                                                            _vm.mallPrice_style,
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .module
                                                                                .extInfo
                                                                                .choiceCurrencyVal
                                                                            ) +
                                                                              _vm._s(
                                                                                _vm.formatePrize(
                                                                                  _vm
                                                                                    .info[
                                                                                    item3
                                                                                      .fieldKey
                                                                                  ]
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.list,
                                                function (item2) {
                                                  return [
                                                    !_vm.showSalePromitionPrice &&
                                                    _vm._extInfo
                                                      .isShowMarketPrice &&
                                                    (item2.type ==
                                                      _vm.SYS_MALL_MARKET_PRICE ||
                                                      item2.type ==
                                                        _vm.SYS_PRICE)
                                                      ? [
                                                          _c(
                                                            "p",
                                                            {
                                                              key:
                                                                "k_" + item2.id,
                                                              staticClass:
                                                                "f-priceWrap g_link productParam",
                                                              class:
                                                                _vm.marketPriceClass,
                                                            },
                                                            [
                                                              _vm.module.content
                                                                .dp.sp &&
                                                              _vm.module.content
                                                                .proStyle != 3
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "productParamKey g_productMallPrice",
                                                                      style:
                                                                        _vm.mallPrice_style,
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item2.name
                                                                        ) + " :"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "marketPrice g_productMallPrice",
                                                                  style:
                                                                    _vm.mallPrice_style,
                                                                },
                                                                [
                                                                  _vm.siteCurrencyRight
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "g_productMallPrice deleteLine",
                                                                          style:
                                                                            _vm.mallPrice_style,
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.formatePrize(
                                                                                _vm
                                                                                  .info[
                                                                                  item2
                                                                                    .fieldKey
                                                                                ]
                                                                              )
                                                                            ) +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .module
                                                                                  .extInfo
                                                                                  .choiceCurrencyVal
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "g_productMallPrice deleteLine",
                                                                          style:
                                                                            _vm.mallPrice_style,
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .module
                                                                                .extInfo
                                                                                .choiceCurrencyVal
                                                                            ) +
                                                                              _vm._s(
                                                                                _vm.formatePrize(
                                                                                  _vm
                                                                                    .info[
                                                                                    item2
                                                                                      .fieldKey
                                                                                  ]
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showBottomCar
                                ? _c("mall-cart", {
                                    attrs: {
                                      info: _vm.info,
                                      holder: _vm.showMallCartHolder,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _vm.info.vipName &&
                  !_vm.isHorizontalVip &&
                  _vm.extInfo.isShowMallPrice
                    ? _c(
                        "span",
                        { staticClass: "vipName g_bgColor g_main_bgColor_v3" },
                        [_vm._v(_vm._s(_vm.info.vipName))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.module.content.t == 1 && _vm.module.content.ct == 3
                    ? _c(
                        "div",
                        { staticClass: "cartToRight", style: _vm.ct3AndCart3 },
                        [
                          _vm.showMallCartFlag
                            ? _c("mall-cart", { attrs: { info: _vm.info } })
                            : _vm._e(),
                        ],
                        1
                      )
                    : [
                        _vm.showMallCartFlag
                          ? _c("mall-cart", { attrs: { info: _vm.info } })
                          : _vm._e(),
                      ],
                ],
                2
              )
            : _vm._e(),
        ],
        2
      )
    : !_vm.isNewAddModule
    ? _c(
        "div",
        {
          class: [_vm.paramCollectionWrapClass, _vm.needPaddingWrapClass],
          style: _vm.paramCollectionWrapStyle,
        },
        [
          _vm.showProName
            ? _c("product-name", { attrs: { info: _vm.info } })
            : _vm._e(),
          _vm._v(" "),
          _vm.showTopMallCart
            ? _c("mall-cart", {
                attrs: { info: _vm.info, holder: _vm.showMallCartHolder },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.list, function (item, i) {
            return [
              item.type == _vm.SYS_MALL_PRICE && item.allowed
                ? [
                    _vm.showMallPrice && !_vm.salePromotionOpen
                      ? [
                          !(
                            _vm.extInfo.isShowPromotionPrice &&
                            _vm.salePromotionOpen
                          )
                            ? _c("p", {
                                key: `smp_${i}`,
                                staticClass: "paramLine",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              key: `smpp_${i}`,
                              staticClass:
                                "f-mallPriceWrap g_link productParam paramPrice g_productParamter",
                              class: _vm.mallPriceWrapClass,
                              style: _vm.productParamter_style,
                            },
                            [
                              _vm.module.content.dp.sp &&
                              _vm.module.content.proStyle != 3 &&
                              !_vm.style2
                                ? _c(
                                    "span",
                                    { staticClass: "productParamKey" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.module.extInfo.bookingOpen
                                            ? ""
                                            : item.name + "："
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.siteCurrencyRight
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "g_main_color_v3",
                                      class: _vm.priceClass,
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatePrize(
                                            _vm.info["actualPrice"]
                                          )
                                        ) +
                                          _vm._s(
                                            _vm.module.extInfo.choiceCurrencyVal
                                          )
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass: "g_main_color_v3",
                                      class: _vm.priceClass,
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.module.extInfo.choiceCurrencyVal
                                        ) +
                                          _vm._s(
                                            _vm.formatePrize(
                                              _vm.info["actualPrice"]
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                              _vm._v(" "),
                              _vm.info.vipName
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "vipName g_bgColor g_main_bgColor_v3",
                                    },
                                    [_vm._v(_vm._s(_vm.info.vipName))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showMallCart.mCart && !_vm.salePromotionOpen
                                ? _c("mall-cart", { attrs: { info: _vm.info } })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                : item.type == _vm.SYS_MALL_MARKET_PRICE && item.allowed
                ? [
                    _vm.showMarketPrice && _vm.info[item.fieldKey] != -1
                      ? _c(
                          "p",
                          {
                            key: "k_" + item.id,
                            staticClass:
                              "g_link productParam g_productParamter",
                            class: _vm.marketPriceClass,
                            style: _vm.productParamter_style,
                          },
                          [
                            _vm.module.content.dp.sp &&
                            _vm.module.content.proStyle != 3 &&
                            !_vm.style2
                              ? _c("span", { staticClass: "productParamKey" }, [
                                  _vm._v(_vm._s(item.name) + "："),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.siteCurrencyRight
                              ? _c("span", { staticClass: "marketPrice" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatePrize(_vm.info[item.fieldKey])
                                    ) +
                                      _vm._s(
                                        _vm.module.extInfo.choiceCurrencyVal
                                      )
                                  ),
                                ])
                              : _c("span", { staticClass: "marketPrice" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.module.extInfo.choiceCurrencyVal
                                    ) +
                                      _vm._s(
                                        _vm.formatePrize(
                                          _vm.info[item.fieldKey]
                                        )
                                      )
                                  ),
                                ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                : item.type == _vm.SYS_SALEPROMOTIONPRICE && item.allowed
                ? [
                    _vm.showSalePromitionPrice &&
                    _vm.style2 &&
                    !_vm.isNewAddModule
                      ? _c("p", { key: `ss1_${i}`, staticClass: "paramLine" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showSalePromitionPrice
                      ? _c(
                          "p",
                          {
                            key: "k_" + item.id,
                            staticClass:
                              "f-mallPriceWrap g_link productParam g_productParamter",
                            class: _vm.mallPriceWrapClass,
                            style: _vm.productParamter_style,
                          },
                          [
                            _vm.module.content.dp.sp &&
                            _vm.module.content.proStyle != 3 &&
                            !_vm.style2
                              ? _c("span", { staticClass: "productParamKey" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.module.extInfo.bookingOpen
                                        ? ""
                                        : item.name + "："
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.siteCurrencyRight
                              ? _c(
                                  "span",
                                  { staticClass: "g_main_color_v3 mallPrice" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatePrize(
                                          _vm.info[item.fieldKey]
                                        )
                                      ) +
                                        _vm._s(
                                          _vm.module.extInfo.choiceCurrencyVal
                                        )
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  { staticClass: "g_main_color_v3 mallPrice" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.module.extInfo.choiceCurrencyVal
                                      ) +
                                        _vm._s(
                                          _vm.formatePrize(
                                            _vm.info[item.fieldKey]
                                          )
                                        )
                                    ),
                                  ]
                                ),
                            _vm._v(" "),
                            _vm.showMallCart.mCart
                              ? _c("mall-cart", { attrs: { info: _vm.info } })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.list, function (item2, i) {
                      return [
                        _vm._extInfo.isShowMarketPrice &&
                        (item2.type == _vm.SYS_MALL_MARKET_PRICE ||
                          item2.type == _vm.SYS_PRICE)
                          ? [
                              _c(
                                "p",
                                {
                                  key: `showmark_${i}`,
                                  staticClass:
                                    "g_link productParam g_productParamter",
                                  class: _vm.marketPriceClass,
                                  style: _vm.productParamter_style,
                                },
                                [
                                  _vm.module.content.dp.sp &&
                                  _vm.module.content.proStyle != 3 &&
                                  !_vm.style2
                                    ? _c(
                                        "span",
                                        { staticClass: "productParamKey" },
                                        [_vm._v(_vm._s(item2.name) + "：")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.siteCurrencyRight
                                    ? _c(
                                        "span",
                                        { staticClass: "marketPrice" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatePrize(
                                                _vm.info[item2.fieldKey]
                                              )
                                            ) +
                                              _vm._s(
                                                _vm.module.extInfo
                                                  .choiceCurrencyVal
                                              )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "marketPrice" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.module.extInfo
                                                .choiceCurrencyVal
                                            ) +
                                              _vm._s(
                                                _vm.formatePrize(
                                                  _vm.info[item2.fieldKey]
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.list, function (item3, i) {
                      return [
                        _vm._extInfo.isShowMallPrice &&
                        !_vm._extInfo.isShowMarketPrice &&
                        item3.type == _vm.SYS_MALL_PRICE
                          ? [
                              _c(
                                "p",
                                {
                                  key: `showmllp_${i}`,
                                  staticClass:
                                    "g_link productParam g_productParamter",
                                  class: _vm.marketPriceClass,
                                  style: _vm.productParamter_style,
                                },
                                [
                                  _vm.module.content.dp.sp &&
                                  _vm.module.content.proStyle != 3 &&
                                  !_vm.style2
                                    ? _c(
                                        "span",
                                        { staticClass: "productParamKey" },
                                        [_vm._v(_vm._s(item3.name) + "：")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.siteCurrencyRight
                                    ? _c(
                                        "span",
                                        { staticClass: "marketPrice" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatePrize(
                                                _vm.info[item3.fieldKey]
                                              )
                                            ) +
                                              _vm._s(
                                                _vm.module.extInfo
                                                  .choiceCurrencyVal
                                              )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "marketPrice" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.module.extInfo
                                                .choiceCurrencyVal
                                            ) +
                                              _vm._s(
                                                _vm.formatePrize(
                                                  _vm.info[item3.fieldKey]
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    }),
                  ]
                : _vm.style2 &&
                  (!_vm.showParamHasEdit ||
                    (_vm.showParamHasEdit && _vm.module.content.dp.sp) ||
                    (_vm.showParamHasEdit && !_vm.module.content.dp.sp))
                ? [
                    _c(
                      "p",
                      {
                        key: "k_" + item.id,
                        staticClass:
                          "g_link productParam proParamNoWrap g_productParamter",
                        class: _vm.classes2,
                        style: _vm.productParamter_style,
                      },
                      [
                        _vm.module.content.dp.sp
                          ? [_vm._v(_vm._s(item.name) + "：")]
                          : _vm._e(),
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.getValue(item)) +
                            "\n            "
                        ),
                      ],
                      2
                    ),
                  ]
                : _vm._isTwoCol && _vm.info[item.fieldKey] == ""
                ? [
                    _c(
                      "p",
                      {
                        key: "k_" + item.id,
                        staticClass: "g_link productParam g_productParamter",
                        class: _vm.otherPropClass,
                        style: _vm.productParamter_style,
                      },
                      [
                        _vm.module.content.dp.sp &&
                        _vm.module.content.proStyle != 3 &&
                        !_vm.style2
                          ? _c("span", { staticClass: "productParamKey" }, [
                              _vm._v(_vm._s(item.name) + "："),
                            ])
                          : _vm._e(),
                        _vm._v("\n                 \n            "),
                      ]
                    ),
                  ]
                : _c(
                    "p",
                    {
                      key: "k_" + item.id,
                      staticClass: "g_link productParam g_productParamter",
                      class: _vm.otherPropClass,
                      style: _vm.productParamter_style,
                    },
                    [
                      _vm.module.content.dp.sp &&
                      _vm.module.content.proStyle != 3 &&
                      !_vm.style2
                        ? _c("span", { staticClass: "productParamKey" }, [
                            _vm._v(_vm._s(item.name) + "："),
                          ])
                        : _vm._e(),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.getValue(item)) +
                          "\n        "
                      ),
                    ]
                  ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }