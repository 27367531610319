<template>
    <div class="propPanel">
        <template v-for="(item, index) in filterProp">
            <template v-if="showPar">
                <!-- 商城价(实际交易价) -->
                <template v-if="item.type == SYS_MALL_PRICE">
                    <param-wrap :key="'props_' + index" :type="textAlignType">
                        <span class="g_main_color_v3 mallPrice" v-text="mallPrice1(item)"></span>
                        <span class="prVipName g_bgColor g_main_bgColor_v3">{{ options.vipName }}</span>
                        <mcart
                            v-if="showMallCart"
                            :options="options"
                            :text="info.shopBtnTypeText"
                            :pid="info.id"
                            :type="content.type"
                            :form-id="info.productFormId"
                        />
                    </param-wrap>
                    <!-- 开启促销划商城价 -->
                    <template v-if="options.salePromotionOpen && info.mallPrice != 0">
                        <param-wrap
                            v-if="info.isSaleTypeDis || (realPrice < cancelPrice && info.hasMarketPrice)"
                            :key="'props_' + index"
                            :type="textAlignType"
                        >
                            <span v-if="info.isSaleTypeDis" class="marketPrice" v-text="mallPrice2(item)"></span>
                            <span v-else class="marketPrice" v-text="mallPrice3(item)"></span>
                        </param-wrap>
                    </template>
                </template>

                <param-wrap
                    v-else-if="item.type == SYS_MALL_MARKET_PRICE"
                    :key="'props_' + index"
                    :type="textAlignType"
                >
                    <!-- 市场价 -->
                    <span
                        v-if="getArgsParam(item.id).realPrice < getArgsParam(item.id).cancelPrice"
                        class="marketPrice"
                        v-text="mallPrice4(item)"
                    ></span>
                </param-wrap>
                <param-wrap v-else :key="'props_' + index" :type="textAlignType">
                    <span v-html="item.name + symbol + getValue(item)"></span>
                </param-wrap>
            </template>
            <template v-else>
                <!-- 商城价(实际交易价) -->
                <template v-if="item.type == SYS_MALL_PRICE">
                    <param-wrap :key="'props_' + index" :type="textAlignType">
                        <span v-if="siteCurrencyRight" class="g_main_color_v3 mallPrice"
                            >{{ getArgsParam(item.id).formatRealPrice }}{{ options.choiceCurrencyVal }}</span
                        >
                        <span v-else class="g_main_color_v3 mallPrice"
                            >{{ options.choiceCurrencyVal }}{{ getArgsParam(item.id).formatRealPrice }}</span
                        >
                        <span class="prVipName g_bgColor g_main_bgColor_v3">{{ options.vipName }}</span>
                        <mcart
                            v-if="showMallCart"
                            :options="options"
                            :pid="info.id"
                            :type="content.type"
                            :text="info.shopBtnTypeText"
                            :form-id="info.productFormId"
                        />
                    </param-wrap>
                    <!-- 开启促销划商城价 -->
                    <template v-if="options.salePromotionOpen && info.mallPrice != 0">
                        <param-wrap
                            v-if="
                                info.isSaleTypeDis ||
                                (getArgsParam(item.id).realPrice < getArgsParam(item.id).cancelPrice &&
                                    info.hasMarketPrice)
                            "
                            :key="'props_' + index"
                            :type="textAlignType"
                        >
                            <span v-if="info.isSaleTypeDis" class="marketPrice" v-text="mallPrice5(item)"></span>
                            <span v-else class="marketPrice" v-text="mallPrice6(item)"></span>
                        </param-wrap>
                    </template>
                </template>
                <p v-else-if="item.type == SYS_MALL_MARKET_PRICE" :key="'props_' + index" :class="textAlign">
                    <!-- 市场价 -->
                    <span
                        v-if="getArgsParam(item.id).realPrice < getArgsParam(item.id).cancelPrice"
                        class="marketPrice"
                        v-text="mallPrice7(item)"
                    ></span>
                </p>
                <p
                    v-else-if="getValue(item) != ''"
                    :key="'props_' + index"
                    :class="textAlign"
                    v-html="getValue(item)"
                ></p>
            </template>
        </template>
        <template v-if="content.type == 3">
            <p v-if="booking_system && bookingOpen" class="proListBookingBtnWrap">
                <span
                    :id="'proListBookingBtn' + info.id"
                    :_pid="info.id"
                    class="g_button proListBookingBtn g_main_bgColor_v3"
                    >{{ encodeHtml(info.shopBtnTypeText) }}</span
                >
            </p>
            <p
                v-else-if="productFormOpen && productFormShow == 1"
                class="proListBookingBtnWrap"
                @click.stop.prevent="productFormBtnClick"
            >
                <span :id="'productFormBtn' + info.id" class="g_button proListBookingBtn g_main_bgColor_v3">{{
                    encodeHtml(info.shopBtnTypeText)
                }}</span>
            </p>
        </template>
    </div>
</template>

<script>
import mcart from './mcart.vue';
import { productResultMixins } from '../mixins';
import { bookingPanelClickEvent } from '@/modules/shared/product/product';
import { productFormBtnClick } from '@/modules/shared/product/btn';
export default {
    name: 'PropsPanel',
    components: {
        mcart,
        'param-wrap': {
            template: `
                <p :class="textAlign" class='g_link productParam proParamNoWrap paramWrap'>
                    <slot />
                </p>
            `,
            props: {
                type: {
                    type: Number,
                    default: -1,
                },
            },
            computed: {
                textAlign() {
                    //第一种样式时起效
                    return this.type == 0 ? 'textAlign_center' : 'textAlign_left';
                },
            },
        },
    },
    mixins: [productResultMixins],
    inject: ['lcid', 'moduleId'],
    props: {
        info: {
            // 产品info
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            SYS_MALL_PRICE: 11,
            SYS_MALL_MARKET_PRICE: 12,
        };
    },
    computed: {
        prop() {
            return this.info.showPropList;
        },
        filterProp() {
            let sb2 = [],
                htmlPrice = [],
                marketPrice = [];
            this.prop.forEach((element) => {
                if (element.type == this.SYS_MALL_PRICE) {
                    htmlPrice.push(element);
                } else if (element.type == this.SYS_MALL_MARKET_PRICE) {
                    marketPrice.push(element);
                } else {
                    sb2.push(element);
                }
            });

            if (this.isLinkProduct) {
                return [];
            }

            return [...sb2, ...htmlPrice, ...marketPrice];
        },
        argsParam() {
            return this.info.showPropListOtherArgs;
        },
        displayPar() {
            // 产品参数
            return this.content.disParam;
        },
        fontSetting() {
            // 产品文字设置
            return this.content.fs;
        },
        moduleProductForm() {
            //产品表单设置
            return this.content.pf;
        },
        productFormShow() {
            return this.moduleProductForm.s;
        },
        showPar() {
            return this.displayPar.sp;
        },
        nameWrap() {
            return this.fontSetting.nw;
        },
        textAlignType() {
            //第一种样式时对齐才起效
            return this.content.type == 1 ? this.fontSetting.ta : 1;
        },
        textAlign() {
            return this.textAlignType == 0 ? 'textAlign_center' : 'textAlign_left';
        },
        symbol() {
            return [2052, 1028].includes(this.lcid) ? '：' : ':';
        },
        mall_cart() {
            return this.content.ml;
        },
        booking_system() {
            return this.content.bk;
        },
        isLinkProduct() {
            return this.info.productType == 4;
        },
        isOpenKnowPd() {
            let isKnowProduct = this.info.productType == 2; // 知识付费
            return this.options.openKnowPayment && isKnowProduct;
        },
        bookingOpen() {
            return this.options.bookingOpen && !this.isOpenKnowPd && !this.isLinkProduct;
        },
        productFormOpen() {
            return this.options.productFormOpen && !this.isOpenKnowPd && !this.isLinkProduct;
        },
        showMallCart() {
            let show = false;
            let { mall_cart, mallOpen, _sessionMemberId, booking_system, productFormShow } = this.options;
            let { MemberPrivileges } = this.info;
            if (mall_cart && mallOpen) {
                if ((!MemberPrivileges && _sessionMemberId != 0) || MemberPrivileges) {
                    show = true;
                }
            }
            if (this.content.type == 3) {
                if (booking_system && this.bookingOpen) {
                    show = false;
                }
            } else {
                if (booking_system && this.bookingOpen) {
                    show = true;
                } else if (this.productFormOpen && productFormShow == 1) {
                    show = true;
                }
            }

            return show && !this.isOpenKnowPd && !this.isLinkProduct;
        },
        siteCurrencyRight() {
            return this.options.siteCurrencyRight && this.options.choiceCurrencyVal != '￥';
        },
    },
    mounted() {
        if (this.content.type == 3) {
            let { booking_system, bookingOpen } = this.options;
            if (booking_system && bookingOpen) {
                bookingPanelClickEvent(`proListBookingBtn${this.info.id}`, 1);
            }
        }
    },
    methods: {
        getValue(item) {
            let searchword = this.getKeyInRounter('searchword');
            let paramKey = item.fieldKey;
            let value = this.info[paramKey];
            if (item.id === 16 && item.unit) {
                // 重量
                value = value + item.unit;
            }
            return this.highLightKeyword(value, searchword);
        },
        getArgsParam(id) {
            return this.argsParam[id];
        },
        productFormBtnClick() {
            productFormBtnClick(this.info.productFormId, this.info.id);
            return false;
        },

        mallPrice1(item) {
            let { name, id } = item;

            return this.siteCurrencyRight
                ? `${name + this.symbol + this.getArgsParam(id).formatRealPrice + this.options.choiceCurrencyVal}`
                : `${name + this.symbol + this.options.choiceCurrencyVal + this.getArgsParam(id).formatRealPrice}`;
        },
        mallPrice2(item) {
            return this.siteCurrencyRight
                ? `商城价${this.symbol + this.getArgsParam(item.id).formatMallPrice + this.options.choiceCurrencyVal}`
                : `商城价${this.symbol + this.options.choiceCurrencyVal + this.getArgsParam(item.id).formatMallPrice}`;
        },
        mallPrice3(item) {
            return this.siteCurrencyRight
                ? `${
                      item.name +
                      this.symbol +
                      this.getArgsParam(item.id).formatCancelPrice +
                      this.options.choiceCurrencyVal
                  }`
                : `${
                      item.name +
                      this.symbol +
                      this.options.choiceCurrencyVal +
                      this.getArgsParam(item.id).formatCancelPrice
                  }`;
        },
        mallPrice4(item) {
            return this.siteCurrencyRight
                ? `${
                      item.name +
                      this.symbol +
                      this.getArgsParam(item.id).formatCancelPrice +
                      this.options.choiceCurrencyVal
                  }`
                : `${
                      item.name +
                      this.symbol +
                      this.options.choiceCurrencyVal +
                      this.getArgsParam(item.id).formatCancelPrice
                  }`;
        },
        mallPrice5(item) {
            return this.siteCurrencyRight
                ? `${this.getArgsParam(item.id).formatMallPrice + this.options.choiceCurrencyVal}`
                : `${this.options.choiceCurrencyVal + this.getArgsParam(item.id).formatMallPrice}`;
        },
        mallPrice6(item) {
            return this.siteCurrencyRight
                ? `${this.getArgsParam(item.id).formatCancelPrice + this.options.choiceCurrencyVal}`
                : `${this.options.choiceCurrencyVal + this.getArgsParam(item.id).formatCancelPrice}`;
        },
        mallPrice7(item) {
            return this.siteCurrencyRight
                ? `${this.getArgsParam(item.id).formatCancelPrice + this.options.choiceCurrencyVal}`
                : `${this.options.choiceCurrencyVal + this.getArgsParam(item.id).formatCancelPrice}`;
        },
    },
};
</script>

<style>
.mProductList .proListBookingBtn,
.f-mallPriceWrap .proListBookingBtn {
    height: 1.15rem;
    line-height: 1.15rem;
    width: auto;
    min-width: 1.8rem;
    max-width: 2.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0.25rem;
    margin: 0;
    float: right;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    font-size: 0.55rem;
    position: relative;
    top: -0.1rem;
}
#mProductList3 .productPicListForm .proListBookingBtn {
    margin: 0.5rem 1rem 0 0;
}

.mProductList .proListBookingBtnWrap {
    display: flex;
    justify-content: flex-start;
}
</style>
