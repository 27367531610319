var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file_upload_container2" },
    [
      _c("div", { staticClass: "preview_file_container" }, [
        _c(
          "ul",
          { staticClass: "preview_file_list" },
          _vm._l(_vm.uploadPreviewFileList, function (fileItem) {
            return _c(
              "li",
              {
                key: fileItem.key,
                staticClass: "preview_file_item",
                attrs: { id: _vm.fileItemId(fileItem) },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "preview_file_item_box preview_file_item_content",
                  },
                  [
                    fileItem.fileType == "image"
                      ? _c("img", {
                          staticClass: "preview_file_item_box--img",
                          attrs: { src: fileItem.src, alt: fileItem.title },
                          on: {
                            click: function ($event) {
                              return _vm.openPhotoSlidesshow(fileItem.key)
                            },
                          },
                        })
                      : fileItem.fileType == "pdf"
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "preview_file_item_box--pdf preview_file_item_box--icon",
                            class: _vm.fileIconClass(fileItem.fileType),
                            attrs: { target: "_blank", href: fileItem.src },
                          },
                          [
                            _c("svg", { staticClass: "file_icon" }, [
                              _c("use", {
                                attrs: {
                                  "xlink:href": _vm.transformSvg(
                                    fileItem.title
                                  ),
                                },
                              }),
                            ]),
                          ]
                        )
                      : _c(
                          "i",
                          {
                            staticClass:
                              "preview_file_item_box--icon preview_file_item_box--other",
                            class: _vm.fileIconClass(fileItem.fileType),
                          },
                          [
                            _c("svg", { staticClass: "file_icon" }, [
                              _c("use", {
                                attrs: {
                                  "xlink:href": _vm.transformSvg(
                                    fileItem.title
                                  ),
                                },
                              }),
                            ]),
                          ]
                        ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "preview_file_item--container preview_file_item_content",
                  },
                  [
                    fileItem.fileType == "pdf"
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "preview_file_item_title preview_file_item_title--pdf",
                            attrs: { target: "_blank", href: fileItem.src },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(fileItem.title || "") +
                                "\n                    "
                            ),
                          ]
                        )
                      : _c("p", { staticClass: "preview_file_item_title" }, [
                          _vm._v(_vm._s(fileItem.title || "")),
                        ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: fileItem.status == "success",
                            expression: "fileItem.status == 'success'",
                          },
                        ],
                        staticClass: "preview_file_item_des",
                      },
                      [_vm._v(_vm._s(_vm.previewSize(fileItem.size)))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "preview_file_item--action preview_file_item_content",
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "preview_file_item_del action_icon",
                        on: {
                          click: function ($event) {
                            return _vm.delUploadFile(fileItem.key)
                          },
                        },
                      },
                      [
                        _c("use", {
                          attrs: { "xlink:href": "#jzm-vicon_delete" },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.uploadPreviewFileList.length < _vm.fileNumLimit,
              expression: "uploadPreviewFileList.length < fileNumLimit",
            },
          ],
          ref: "uploadBtn2",
          staticClass: "uploadify-button2",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.uploadClick.apply(null, arguments)
            },
          },
        },
        [
          _c("span", [
            _c(
              "svg",
              {
                staticClass: "icon_add",
                on: {
                  click: function ($event) {
                    return _vm.delUploadFile(_vm.fileItem.key)
                  },
                },
              },
              [_c("use", { attrs: { "xlink:href": "#icon_add" } })]
            ),
            _vm._v(
              "\n            " +
                _vm._s(_vm.extInfo.clickToUpload) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _vm.showFileLimitTips
            ? _c("span", [_vm._v("（" + _vm._s(_vm.fileLimitTips) + "）")])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showMobiViewer
        ? _c("image-viewer", {
            attrs: {
              "z-index": _vm.zIndex,
              "initial-index": _vm.initialIndex,
              "on-close": _vm.onClose,
              "url-list": _vm.urlList,
              "is-mobi": true,
              "content-terminal": "mobi",
              "manage-mode": _vm.manageMode,
              "log-event-config": _vm.logEventConfig,
              "document-event-el": _vm.documentEventEl,
              "append-to-container-id": "g_body",
              "append-to-body": false,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }