import { deepExtendClone } from '@jz/utils';

import { getLanCode } from './fai';

export function encodeHtml(html) {
    return html && html.replace
        ? html
              .replace(/&/g, '&amp;')
              .replace(/ /g, '&nbsp;')
              .replace(/\b&nbsp;+/g, ' ')
              .replace(/</g, '&lt;')
              .replace(/>/g, '&gt;')
              .replace(/\\/g, '&#92;')
              .replace(/'/g, '&#39;')
              .replace(/"/g, '&quot;')
              .replace(/\n/g, '<br/>')
              .replace(/\r/g, '')
        : html;
}

export function decodeHtml(html) {
    return html && html.replace
        ? html
              .replace(/&nbsp;/gi, ' ')
              .replace(/&lt;/gi, '<')
              .replace(/&gt;/g, '>')
              .replace(/&#92;/gi, '\\')
              .replace(/&#39;/gi, "'")
              .replace(/&quot;/gi, '"')
              .replace(/<br\/>/gi, '\n')
              .replace(/&amp;/gi, '&')
        : html;
}
export function decodeHtmlAttr(html) {
    return html && html.replace
        ? html
              .replace(/&#x22;/gi, '"')
              .replace(/&#x27;/gi, "'")
              .replace(/&#x3c;/gi, '<')
              .replace(/&#x3e;/gi, '>')
              .replace(/&#x26;/gi, '&')
              .replace(/&#5c;/gi, '\\')
        : html;
}

export function encodeHtmlJs(html) {
    return html && html.replace
        ? html
              .replace(/\\/g, '\\\\')
              .replace(/'/g, '\\x27')
              .replace(/"/g, '\\x22')
              .replace(/\n/g, '\\n')
              .replace(/</g, '\\x3c')
              .replace(/>/g, '\\x3e')
        : html;
}

export function encodeUrl(url) {
    return typeof url === 'undefined' ? '' : encodeURIComponent(url);
}

// 进行url解码
export function decodeUrl(url) {
    return typeof url === 'undefined' ? '' : decodeURIComponent(url);
}

/**
 * @deprecated
 * 此方法用来处理请求多语言自动拼接，目前请求库已内置，调用到此方法的地方直接把newPath作为参数传递给request即可
 *
 * @export
 * @param {*} {
 *     newPath,
 *     // oldPath,
 *     // useNewPrefix = true,
 *     // useNew = false,
 * }
 * @return {*}
 */
export function addRequestPrefix({
    newPath,
    // eslint-disable-next-line no-unused-vars
    oldPath,
    // eslint-disable-next-line no-unused-vars
    useNewPrefix = true,
    // eslint-disable-next-line no-unused-vars
    useNew = false,
}) {
    const lanCode = getLanCode();
    const lanCodePrefix = lanCode === '' ? '' : `/${lanCode}`;
    return lanCodePrefix + newPath;
}

/**
 * 动态加载文件到doc中，并依据obj来设置属性，加载成功后执行回调函数fn
 * @name loadFile
 * //指定加载到当前document中一个script文件，加载成功后执行function
 * Mobi.loadFile( document, {
 *     src:"test.js",
 *     tag:"script",
 *     type:"text/javascript",
 *     defer:"defer"
 * }, function () {
 *     console.log('加载成功！')
 * });
 */
export const loadFile = (function () {
    var tmpList = [];

    function getItem(doc, obj) {
        try {
            for (var i = 0, ci; (ci = tmpList[i++]); ) {
                if (ci.doc === doc && ci.url == (obj.src || obj.href)) {
                    return ci;
                }
            }
        } catch (e) {
            return null;
        }
    }

    return function (doc, obj, fn, errFn) {
        var item = getItem(doc, obj);
        if (item) {
            if (item.ready) {
                fn && fn(item);
            } else {
                item.funs.push(fn);
                errFn && item.errFuns.push(errFn);
            }
            return;
        }
        tmpList.push({
            doc,
            url: obj.src || obj.href,
            funs: [fn],
            errFuns: errFn ? [errFn] : [],
        });
        if (!doc.body) {
            var html = [];
            for (var p in obj) {
                if (p == 'tag') continue;
                html.push(p + '="' + obj[p] + '"');
            }
            doc.write('<' + obj.tag + ' ' + html.join(' ') + ' ></' + obj.tag + '>');
            return;
        }
        if (obj.id && obj.realTime) {
            var fileExist = doc.getElementById(obj.id);
            if (fileExist && fileExist.parentNode) fileExist.parentNode.removeChild(fileExist);
        }
        if (obj.id && doc.getElementById(obj.id)) {
            return;
        }
        var element = doc.createElement(obj.tag);
        delete obj.tag;
        for (let p in obj) {
            if (Object.hasOwnProperty.call(obj, p)) {
                element.setAttribute(p, obj[p]);
            }
        }
        element.onload = element.onreadystatechange = function () {
            if (!this.readyState || /loaded|complete/.test(this.readyState)) {
                item = getItem(doc, obj);
                if (item.funs.length > 0) {
                    item.ready = 1;
                    for (var fi; (fi = item.funs.pop()); ) {
                        fi(item);
                    }
                }
                element.onload = element.onreadystatechange = null;
            }
        };
        element.onerror = function () {
            item = getItem(doc, obj);
            if (item.errFuns && item.errFuns.length > 0) {
                for (var err; (err = item.errFuns.pop()); ) {
                    err();
                }
            }
            throw Error('加载文件 ' + (obj.href || obj.src) + ' 失败 ');
        };

        doc.getElementsByTagName('head')[0].appendChild(element);
    };
})();

export function commTransformComp(target, defalut) {
    function proxy(func) {
        return function () {
            var retArgs = [];
            var attrs = target.split('.');
            var key = attrs.pop();
            var operObj = this;

            for (var i = 0; i < attrs.length; i++) {
                operObj = operObj[attrs[i]];
            }

            retArgs.push(key);
            retArgs.push(arguments[0]);
            retArgs.push(this);

            return func.apply(operObj, retArgs);
        };
    }

    function getFunc(key, vm) {
        var retDefault;

        if (typeof defalut == 'function') {
            retDefault = defalut.call(vm, vm);
        } else {
            retDefault = defalut || 0;
        }

        if (this[key] === 0) return retDefault;

        if (typeof this[key] === 'undefined') {
            return retDefault;
        }

        return rem2px_noUnit(this[key]);
    }

    function setFunc(key, newValue) {
        this[key] = px2rem_noUnit(newValue);
    }

    return {
        get: proxy(getFunc),
        set: proxy(setFunc),
    };
}

export function px2rem(value, toFixed = 5) {
    var htmlFontSize = window.$store.state.htmlFontSize;
    return (value / htmlFontSize).toFixed(toFixed) + 'rem';
}

export function px2rem_noUnit(value, toFixed = 5) {
    var htmlFontSize = window.$store.state.htmlFontSize;

    return Number((value / htmlFontSize).toFixed(toFixed));
}

export function rem2px_noUnit(value) {
    var htmlFontSize = window.$store.state.htmlFontSize;
    return Math.round(value * htmlFontSize);
}

export function copyData(data) {
    return deepExtendClone(data);
}
export const preciseDecimal = function (number, n) {
    // 不符合格式则return
    if ((isNaN(parseFloat(number)) && !isFinite(number)) || typeof n != 'number' || n <= 0) {
        return number;
    }

    var numberStr = number + '',
        pointIdx = numberStr.indexOf('.'),
        integer,
        decimal;

    // 没有小数点
    if (pointIdx < 0) {
        return number;
    }

    integer = parseInt(numberStr.slice(0, pointIdx)); // 整数部分
    decimal = parseFloat(numberStr.slice(pointIdx, numberStr.length)); // 小数部分

    return integer + parseFloat(decimal.toPrecision(n));
};

/**********使用部分功能要把滚动条隐藏**********/
export const mobiHideScroll = function () {
    var bodyObj = document.getElementById('g_body');
    //var htmlObj = document.documentElement;
    var htmlObj = document.getElementsByTagName('html')[0];

    bodyObj.style.overflowY = 'hidden';
    htmlObj.style.overflowY = 'hidden';
};

/**********使用部分功能结束后要把滚动条显示**********/
export const mobiShowScroll = function () {
    var bodyObj = document.getElementById('g_body');
    //var htmlObj = document.documentElement;
    var htmlObj = document.getElementsByTagName('html')[0];
    bodyObj.style.overflowY = '';
    htmlObj.style.overflowY = '';
    if (VITE_APP_MODE !== 'visitor') {
        let iFrameObj = window.parent.document.getElementById('preview');
        iFrameObj.style.position = '';
        iFrameObj.style.left = '';
        iFrameObj.setAttribute('width', '100%');
    }
};

export const debounce = function (fn, delay) {
    let timer = null;
    return function () {
        let args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn.apply(this, args);
        }, delay);
    };
};

//通过html创建node节点
export function createElementByHtml(html) {
    var el = document.createElement('div');
    el.innerHTML = html;
    el = el.firstChild;
    el.parentNode.removeChild(el);
    return el;
}

export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}
export function range(start, end, unit = '') {
    let length = end - start + 1;
    let step = start - 1;
    return Array.apply(null, { length }).map(() => {
        step++;
        return unit ? `${step}${unit}` : step;
    });
}
function colorIsLinearGradient(color = '') {
    return color.indexOf('linear-gradient') > -1;
}

/*
    新模块统一背景控件出口
*/
export function parseBackgroundCss({ type, repeat, picScale, fileId, filePath, color }) {
    let background = '';

    if (type === 0) {
        background = '';
    } else if (type === 1) {
        background = 'none';
    } else if (type === 2) {
        if (fileId != '') {
            background += 'url(' + filePath + ') ';
            if (picScale == 0) {
                // 平铺
                switch (repeat) {
                    case 3:
                        background += 'repeat center /auto';
                        break; // 默认
                    case 5:
                        background += 'no-repeat center top /100% 100%';
                        break; // 缩放平铺
                    case 11:
                        background += 'repeat-x center top /auto';
                        break; // 上
                    case 1:
                        background += 'repeat-x center center /auto';
                        break; // 中-横向
                    case 12:
                        background += 'repeat-x center bottom /auto';
                        break; // 下
                    case 21:
                        background += 'repeat-y left top /auto';
                        break; // 左
                    case 2:
                        background += 'repeat-y center top /auto';
                        break; // 中-纵向
                    case 22:
                        background += 'repeat-y right top /auto';
                        break; // 右
                }
            } else if (picScale == 1) {
                // 原图
                background += 'no-repeat ';
                switch (repeat) {
                    case 13:
                        background += 'left top';
                        break;
                    case 8:
                        background += 'center top';
                        break;
                    case 14:
                        background += 'right top';
                        break;
                    case 6:
                        background += 'left center';
                        break;
                    case 0:
                        background += 'center center';
                        break;
                    case 7:
                        background += 'right center';
                        break;
                    case 15:
                        background += 'bottom left';
                        break;
                    case 9:
                        background += 'bottom center';
                        break;
                    case 16:
                        background += 'bottom right';
                        break;
                }
            } else if (picScale == 2) {
                // 填充
                background += 'no-repeat center /cover';
            } else if (picScale == 3) {
                // 拉伸
                background += 'no-repeat center /100% 100%';
            } else if (picScale == 4) {
                // 等比例
                background += 'no-repeat center /contain';
            }
        }

        if (colorIsLinearGradient(color) && fileId != '') {
            background += ',' + color;
        } else {
            background += ' ' + color;
        }
    }

    return `background:${background};`;
}
export function getStyle(oElm, strCssRule) {
    var strValue = '';
    if (document.defaultView && document.defaultView.getComputedStyle) {
        strValue = document.defaultView.getComputedStyle(oElm, '').getPropertyValue(strCssRule);
    } else if (oElm.currentStyle) {
        strCssRule = strCssRule.replace(/-(\w)/g, function (strMatch, p1) {
            return p1.toUpperCase();
        });
        strValue = oElm.currentStyle[strCssRule];
    }
    return strValue;
}

export function isPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
}

export const isQQ = function () {
    var ua = window.navigator.userAgent.toLowerCase();
    var mobiType = ['qq']; //mqqbrowser
    function checkList(elem) {
        return ua.indexOf(elem) > -1;
    }
    return mobiType.some(checkList);
};

export function banCurPageContentCopy() {
    jm(document).ready(function () {
        // 管理态或者开启全站防复制，返回
        if (Fai.top.banCopyTextImg || VITE_APP_MODE !== 'visitor') {
            return;
        }

        // 禁用右键菜单、复制、粘贴
        var $document = jm(document);
        // PC端
        $document.on('contextmenu copy cut', function (event) {
            if (!jm(event.target).parents('.richContent').length) {
                return true;
            }
            if (jm(event.target).is('input, textarea')) {
                return;
            } else {
                return false;
            }
        });

        // 手机端
        $document.off('touchend.bc').on('touchend.bc', function () {
            jm('.img_no_select').removeClass('img_no_select');
        });

        // 为了不阻塞其他主要JS，这里延时加载
        jm(window).on('load', function () {
            setTimeout(function () {
                jm('.richContent img').forEach(function (el) {
                    initImgNoSelect(el);
                    banImgDownload(el);
                });
            }, 1000);
        });
        // 禁用文字选择
        let $head = jm('head');
        let banSelectStyle = jm(`
            <style>
            .richContent *{-webkit-touch-callout: none;-moz-touch-callout: none;-ms-touch-callout: none;touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
            </style>`);
        $head.append(banSelectStyle);

        // 禁止图片下载
        function banImgDownload(el) {
            const imgFormat = ['jpg', 'jpeg', 'bmp', 'png', 'gif'];

            let originPath = jm(el).attr('src-original') || '';
            let srcPath = jm(el).attr('src') || '';
            let imgType = '';

            if (!!originPath || !!srcPath) {
                let path = originPath || srcPath;
                const isStaticResImg = path.includes(Fai.top._resImageRoot);
                const isThumbImg = path.includes('!');
                const loadingImgPath = Fai.top._loadingPath;
                let isInnerPath = true;
                // 是否是内链
                isInnerPath = ['faiusr.com', 'faisys.com', 'faiusrd.com', 'co99.net'].some((domain) => {
                    return path.includes(domain);
                });
                // 图片类型
                const webPSuffix = path.indexOf('.webp');

                let suffixIndex = -1;
                if (webPSuffix !== -1) {
                    suffixIndex = path.lastIndexOf('.', webPSuffix);
                } else {
                    suffixIndex = path.lastIndexOf('.');
                }

                // 图片MIME
                imgType = path.substring(suffixIndex + 1);

                if (isInnerPath && imgFormat.includes(imgType) && !isStaticResImg && !isThumbImg) {
                    path = path.slice(0, suffixIndex);
                    // 懒加载
                    if (originPath) {
                        // 还未加载src
                        if (srcPath.includes(loadingImgPath)) {
                            jm(el).attr('src-original', path);
                        } else {
                            // 已经加载src的重新加载
                            srcPath && jm(el).attr('src', path);
                        }

                        // 非懒加载
                    } else {
                        // 这种是没有懒加载的情况
                        srcPath && jm(el).attr('src', path);
                    }
                }
            }
        }

        // 输出域名
    });
}

export function initImgNoSelect(el) {
    var isIphone = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
    var isScrolling = false;
    var startX = 0;
    var startY = 0;
    var dx = 0;
    var dy = 0;
    var $el = jm(el);
    $el.off('touchstart.bc').on('touchstart.bc', function (e) {
        startX = e.touches[0].pageX;
        startY = e.touches[0].pageY;
        $el.addClass('img_no_select'); // 经测试发现，不会阻断后面一次的 touchend 事件
        throw new Error('NO ERRPR:禁止长按弹出的菜单'); // 安卓手机禁用菜单,不使用 e.preventDefault 原因是这会阻隔滚动事件和跳转事件
        //e.preventDefault();
    });
    $el.off('touchmove.bc').on('touchmove.bc', function (e) {
        var moveEndX = e.touches[0].pageX;
        var moveEndY = e.touches[0].pageY;

        dx = moveEndX - startX;
        dy = moveEndY - startY;
        if (Math.abs(dx) > Math.abs(dy) && dx > 0) {
            // right
            isScrolling = true;
        } else if (Math.abs(dx) > Math.abs(dy) && dx < 0) {
            // left
            isScrolling = true;
        } else if (Math.abs(dy) > Math.abs(dx) && dy > 0) {
            // down
            isScrolling = true;
        } else if (Math.abs(dy) > Math.abs(dx) && dy < 0) {
            // up
            isScrolling = true;
        } else {
            //没有发生滑动
            isScrolling = false;
        }
    });
    $el.off('touchend.bc').on('touchend.bc', function () {
        $el.removeClass('img_no_select');
        !isScrolling && isIphone && $el.trigger('click'); // 修复苹果手机不触发 click
    });
}

export function equals(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}

function isFunction(fn) {
    return typeof fn === 'function';
}

export function useIdleCallback(callback, { timeout = void 0 } = {}) {
    if (!isFunction(callback)) {
        throw new Error(`callback must be a function be got ${typeof callback}`);
    }
    var id;
    if (window.requestIdleCallback) {
        id = window.requestIdleCallback(callback, { timeout });
        return [id, window.cancelIdleCallback.bind(window, id)];
    } else {
        id = window.setTimeout(callback, timeout);
        return [id, window.clearTimeout.bind(window, id)];
    }
}

export function useRaf(callback) {
    if (!isFunction(callback)) {
        throw new Error(`callback must be a function be got ${typeof callback}`);
    }
    var lastTime = 0;
    var vendors = ['ms', 'moz', 'webkit', 'o'];
    var requestAnimationFrame;
    var cancelAnimationFrame;
    var id;
    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
        cancelAnimationFrame =
            window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
    }
    if (!requestAnimationFrame) {
        var currTime = new Date().getTime();
        var timeToCall = Math.max(0, 16 - (currTime - lastTime));
        id = window.setTimeout(function () {
            callback(currTime + timeToCall);
        }, timeToCall);
        lastTime = currTime + timeToCall;
        return [id, window.clearTimeout.bind(window, id)];
    } else {
        id = requestAnimationFrame(callback);
        return [id, cancelAnimationFrame.bind(null, id)];
    }
}

export const transformSvg = (filename) => {
    console.log(filename);
    const fileTypeSvg = new Map([
        ['.pdf', '#icon_pdf'],
        ['.doc', '#icon_word'],
        ['.xls', '#icon_exls'],
        ['.xlsx', '#icon_exls'],
        ['.exls', '#icon_exls'],
        ['.docx', '#icon_word'],
        ['.wps', '#icon_word'],
        ['.pptx', '#icon_ppt'],
        ['.ppt', '#icon_ppt'],
        ['.txt', '#icon_txt'],
        ['.epub', '#icon_txt'],
        ['.mp4', '#icon_video'],
        ['.avi', '#icon_video'],
        ['.wmv', '#icon_video'],
        ['.3gp', '#icon_video'],
        ['.mkv', '#icon_video'],
        ['.flv', '#icon_video'],
        ['.rm', '#icon_video'],
        ['.rmvb', '#icon_video'],
        ['.swf', '#icon_video'],
        ['.mp3', '#icon_Music'],
        ['.wav', '#icon_Music'],
        ['.wma', '#icon_Music'],
        ['.ogg', '#icon_Music'],
        ['.ape', '#icon_Music'],
        ['.acc', '#icon_Music'],
        ['.zip', '#icon_GZIP'],
        ['.rar', '#icon_GZIP'],
        ['.jpg', '#icon_image'],
        ['.png', '#icon_image'],
        ['.gif', '#icon_image'],
        ['.bmp', '#icon_image'],
        ['.jpeg', '#icon_image'],
        ['.html', '#icon_html'],
        ['.css', '#icon_html'],
        ['.js', '#icon_js'],
        ['.apk', '#icon_apk'],
    ]);
    for (let fileType of fileTypeSvg.entries()) {
        if (filename) {
            if (filename.toLocaleLowerCase().endsWith(fileType[0])) {
                return fileType[1];
            }
        }
    }
    return '#icon_file';
};
