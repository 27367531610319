import {
    getMAcctMail,
    sendMemberPwdEmail,
    sendMemberPwdMobileCode,
    checkMobileCodeGeneral,
    setMemberPwdGeneral,
    checkPwdEmailCode,
    setMemberPwdByCode,
    setMemberPwdByMobileCode,
    checkFindPwdByMobileCode,
} from '@/api/member';
import { mobiIng } from '@/shared/fai';
import { isNull, isEmail } from '../util';
import { preprocessUrl } from '@/shared/url';
import { changeHref } from '@/features/router/universal';
//找回密码
export const memberFdPwd = function (Lcid, returnUrl) {
    jm('.J_showFindPwWay').html('');
    var macct = jm.trim(jm('#macct').val());
    if (macct == '') {
        mobiIng(LS.memberInputAcct, true);
        document.getElementById('macct').focus();
        return;
    }

    /* jser  2017-2-24 fix 通过手机找回密码
     * @desc 获取js函数调用的行内形式
     *  funcName 函数名字的字符串形式
     *  data:参数数组
     *  quote: 参数两侧要添加的引号  '|"
     * @author: jser
     * 2017-2-24
     */
    getMAcctMail(macct)
        .catch(() => {
            mobiIng(LS.js_systemError, false);
        })
        .then((result) => {
            if (result.success) {
                if (!result.findPwByMobileOpen) {
                    if (result.noMail) {
                        mobiIng(LS.memberDialogNoEmailMsg);
                        return;
                    }
                }

                if (!result.findPwByMailOpen) {
                    if (result.mobile == null || result.mobile == 'null' || result.mobile == '') {
                        mobiIng(LS.memberDialogNoMobile);
                        return;
                    }
                }

                if (result.noMail && (result.mobile == 'null' || result.mobile == '')) {
                    mobiIng(LS.memberDialogNoMailMobile);
                    return;
                }
                // 主题3.0填入会员并且存在的时候，把输入框去掉
                if (Fai.top._openThemeV3) {
                    jm('.two_way_change_wrap').hide();
                }
                jm('.J_memberFdPwdBtn').remove();
                var divHtml = [];
                divHtml.push('<div class="fk-mem-findPwStepOneFindWay"style="">');
                divHtml.push(LS.memberFdPwdWay + ':');
                divHtml.push('</div>');
                if (result.findPwByMailOpen) {
                    //通过邮箱认证
                    divHtml.push('<div class="fk-mem-findPwStepOne stepOneByEmail">');
                    divHtml.push('<span class="content">');
                    divHtml.push(LS.memberFdPwdWayByMail);
                    divHtml.push('</span>');
                    // 主题3.0样式
                    if (!Fai.top._openThemeV3) {
                        divHtml.push('<span class="icon-gGoforward">');
                        divHtml.push('</span>');
                    }
                    divHtml.push('</div>');
                }

                if (result.findPwByMobileOpen) {
                    //通过手机短信认证
                    divHtml.push('<div class="fk-mem-findPwStepOne stepOneByMobile">');
                    divHtml.push('<span class="content">');
                    divHtml.push(LS.memberFdPwdWayByMobile);
                    divHtml.push('</span>');

                    // 主题3.0样式
                    if (!Fai.top._openThemeV3) {
                        divHtml.push('<span class="icon-gGoforward">');
                        divHtml.push('</span>');
                    }
                    divHtml.push('</div>');
                }
                divHtml = divHtml.join('');
                jm('.J_showFindPwWay').append(divHtml);

                if (result.findPwByMailOpen) {
                    jm('.stepOneByEmail')
                        .unbind('click')
                        .bind('click', () => {
                            if (result.noMail) {
                                findPwByMailShowNoMail();
                            } else {
                                findPwByMailStepTwo(macct, Lcid, returnUrl, result.mail);
                            }
                        });
                }

                if (result.findPwByMobileOpen) {
                    jm('.stepOneByMobile')
                        .unbind('click')
                        .bind('click', () => {
                            if (result.mobile == 'null' || result.mobile == '') {
                                findPwByMobileShowNoMb();
                            } else {
                                sendFindPwMobileCode(macct, true, Lcid, returnUrl, result.mobile);
                            }
                        });
                }
            } else {
                if (result.notFound) {
                    mobiIng(LS.memberDialogNotFound, true);
                } else {
                    mobiIng(LS.js_argsError, true);
                }
            }
        });
};

export const acquireCode = function (findPwByMobileOpen, findPwByMailOpen) {
    var acquirer = jm('#acquirerInput').val();
    if (isNull(acquirer) || !acquirer) {
        if (Fai.top._openThemeV3) {
            mobiIng('输入的邮箱或手机不能为空！');
        }
        return false;
    }

    if (findPwByMailOpen && isEmail(acquirer)) {
        sendMemberPwdEmail(acquirer)
            .catch(() => {
                mobiIng(LS.js_systemError);
            })
            .then((result) => {
                if (result.success) {
                    jm('#memCodeSign').val(result.emailCodeSign);
                    acquireCodeCountdown(60, findPwByMobileOpen, findPwByMailOpen);
                } else {
                    if (result.aliasNotF) {
                        mobiIng(LS.memberDialogAliasNotFound, true);
                    } else if (result.notFound) {
                        mobiIng(LS.memberDialogNotFound, true);
                    } else if (result.smtpErr) {
                        mobiIng(LS.smtpErr, true);
                    } else {
                        mobiIng(LS.js_argsError, true);
                    }
                }
            });
    } else if (findPwByMobileOpen && jm.isMobile(acquirer)) {
        sendMemberPwdMobileCode(acquirer)
            .catch(() => {
                mobiIng(LS.js_systemError);
            })
            .then((result) => {
                if (result.success) {
                    jm('#memCodeSign').val(result.mobileCodeSign);
                    Fai.top.tmpMobileCodeSgin = result.mobileCodeSign;
                    acquireCodeCountdown(60, findPwByMobileOpen, findPwByMailOpen);
                } else {
                    if (result.argErr) {
                        mobiIng(LS.memberDialogSendMobileClose);
                    } else if (result.apiKeyNotFound) {
                        mobiIng(LS.memberDialogYunPianErr);
                    } else if (result.tplNotFound) {
                        mobiIng(LS.memberDialogMobileTplErr);
                    } else if (result.notFound) {
                        mobiIng(LS.memberDialogNotFound);
                    } else if (result.limitOne) {
                        mobiIng(LS.getMobileOneMin);
                    } else if (result.limitTwo) {
                        mobiIng(LS.getMobileHalfHour);
                    } else if (result.noMoney) {
                        mobiIng(LS.memberDialogMobileMoneyErr);
                    } else if (result.mobileErr) {
                        mobiIng(LS.memberDialogSendMobileCodeErr);
                    } else if (result.sendLimit) {
                        mobiIng(LS.memberDialogSendMobileCodeLimit);
                    } else if (result.mobileSysErr) {
                        mobiIng(LS.memberDialogSendMobileSysErr);
                    } else if (result.tplNationErr) {
                        mobiIng(LS.mobileNationTplErr);
                    } else if (result.systemErr) {
                        mobiIng(LS.js_systemError);
                    } else if (result.rt == -301) {
                        mobiIng(LS.smsRanout);
                    } else {
                        mobiIng(LS.js_argsError);
                    }
                }
            });
    } else if (jm.isMobile(acquirer)) {
        mobiIng(LS.memberDialogPlzEnterMail);
    } else if (isEmail(acquirer)) {
        mobiIng(LS.memberDialogPlzEnterMobile);
    } else {
        mobiIng(LS.memberDialogPlzEnterBoth);
    }
};

export const memberNewFdPwdStepTwo = function () {
    var memCodeSign = jm('#memCodeSign').val();
    if (isNull(memCodeSign) || !memCodeSign) {
        mobiIng(LS.memberDialogGetCodeFirst);
        return;
    }

    var code = jm('#memCodeInput').val();
    if (isNull(code) || !code) {
        mobiIng(LS.memberInputCaptcha);
        return;
    }

    var mode = '';
    var acquirer = jm('#acquirerInput').val();
    if (isNull(acquirer) || !acquirer) {
        return false;
    } else if (isEmail(acquirer)) {
        mode = 'mail';
    } else if (jm.isMobile(acquirer)) {
        mode = 'mobile';
    } else {
        return false;
    }

    var value = Fai.top._openThemeV3 ? LS.memberOtherLgnSureV3 : LS.memberOtherLgnSure;
    checkMobileCodeGeneral({
        memCode: code,
        memCodeSign,
        acquirer,
        mode,
    })
        .catch(() => {
            mobiIng(LS.js_systemError);
        })
        .then((result) => {
            if (result.success) {
                jm('.loginContent').html('');
                var divHtml = [
                    '<div>',
                    "<input type='password' class='findpswInput itemEdit g_input mustItem' style='padding-left: 0rem; margin-bottom: 0.025rem;' id='newPwInput' placeholder='" +
                        LS.memberDialogNewPwd +
                        "' style='margin-left: 0;'>",
                    "<input type='password' class='findpswInput itemEdit g_input mustItem' style='padding-left: 0rem;' id='assureNewPwInput' placeholder='" +
                        LS.memberDialogAssurePwd +
                        "' style='margin-left: 0;'>",
                    "<div class='g_globalLine J_memberFdPwdBtn  '>",
                    "<input type='button' class='g_button sendIcon fpw_btn g_main_bgColor_v3 ' value='" + value + "'/>",
                    '</div>',
                    '</div>',
                ];

                var divHtmlV3 = [
                    '<div>',
                    "<div class='g_globalLine'>",
                    "<input type='password' class='findpswInput itemEdit g_input mustItem' style='margin-bottom: 0.025rem;' id='newPwInput' placeholder='" +
                        LS.memberDialogNewPwd +
                        "' style='margin-left: 0;'>",
                    "<span class='input_pre_text'>" + LS.memberDialogNewPwd + '</span>',
                    '</div>',
                    "<div class='g_globalLine'>",
                    "<input type='password' class='findpswInput itemEdit g_input mustItem' id='assureNewPwInput' placeholder='" +
                        LS.memberDialogAssurePwd +
                        "' style='margin-left: 0;'>",
                    "<span class='input_pre_text'>" + LS.memberDialogNewPwd + '</span>',
                    '</div>',
                    "<div class='g_globalLine J_memberFdPwdBtn  '>",
                    "<input type='button' class='g_button sendIcon fpw_btn g_main_bgColor_v3 ' value='" + value + "'/>",
                    '</div>',
                    '</div>',
                ];
                if (Fai.top._openThemeV3) {
                    divHtmlV3 = divHtmlV3.join('');
                    jm('.loginContent').html(divHtmlV3);
                } else {
                    divHtml = divHtml.join('');
                    jm('.loginContent').html(divHtml);
                }
                jm('.sendIcon')
                    .unbind('click')
                    .bind('click', () => {
                        memberNewFdPwdLastStep(result.memId);
                    });
            } else {
                if (result.notFound) {
                    mobiIng(LS.memberDialogNotFound, true);
                } else if (result.rt == 1) {
                    mobiIng(LS.memberSignupRegisterCaptchaNotMatch);
                }
            }
        });
};
export const acquireCodeCountdown = function (sec) {
    jm('.acquireCode').attr('sec', '');
    var countdown = setInterval(function () {
        jm('.acquireCode').attr('sec', sec);
        jm('.acquireCode').text(sec + '秒后重发');
        if (sec < 0) {
            clearInterval(countdown);
            jm('.acquireCode').text(LS.memberDialogAcquireCode);
            jm('.acquireCode').attr('sec', '');
        }
        sec--;
    }, 1000);
    // Mobi.acquireCode
};

const memberNewFdPwdLastStep = function (memId) {
    var newPw = jm('#newPwInput').val();
    if (isNull(newPw) || !newPw) {
        mobiIng(LS.memberDialogPleaseEnterPwd);
        return;
    }

    var assureNewPw = jm('#assureNewPwInput').val();
    if (isNull(assureNewPw) || !assureNewPw) {
        mobiIng(LS.memberDialogPleaseEnterPwd2);
        return;
    }

    if (newPw !== assureNewPw) {
        mobiIng(LS.memberDialogPwdDifToPwd2);
        return;
    }
    if (newPw.length < 4 || newPw.length > 20) {
        mobiIng(jm.format(LS.memberDialogPwdLimit, 4, 20));
        return;
    }

    setMemberPwdGeneral(jm.md5(newPw), memId)
        .catch(() => {
            mobiIng(LS.js_systemError);
        })
        .then((result) => {
            if (result.success) {
                jm('.loginContent').html('');
                var href = preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp';
                var divHtml = [
                    '<div class="formMiddleContent moduleContent" id="memberFdPwdStepLast">',
                    '<div class="loginContent">',
                    '<div class="g_globalLine" style="text-align: center;">',
                    '<div class="g_circle" style="margin: 1rem auto 1.5rem">',
                    '<span class="icon-lockstarIcon g_mainColor "></span>',
                    '</div>',
                    '<span class="title">',
                    LS.memberDialogPwdforced,
                    '</span>',
                    '</div>',
                    '<div class="g_globalLine" style="margin-top:1.5rem;">',
                    '<input type="button" class="g_button sendIcon" value="',
                    LS.memberDialogLoginInstantly,
                    '"/>',
                    '</div> ',
                    '</div>',
                    '</div>',
                ];
                var hrefV3 = preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp';
                var divHtmlV3 = [
                    '<div class="formMiddleContent moduleContent" id="memberFdPwdStepLast">',
                    '<div class="loginContent">',
                    '<div class="g_globalLine" style="text-align: center;">',
                    '<div class="g_circle " style="margin: 1rem auto 1.5rem">',
                    '<span class="icon-lockstarIcon g_mainColor  g_main_color_v3 "></span>',
                    '</div>',
                    '<span class="title">',
                    LS.memberDialogPwdforcedV3,
                    '</span>',
                    '</div>',
                    '<div class="g_globalLine" style="margin-top:1.5rem;">',
                    '<input type="button" class="g_button sendIcon g_main_bgColor_v3  fpw_btn" value="',
                    LS.memberDialogLoginInstantly,
                    '"/>',
                    '</div> ',
                    '</div>',
                    '</div>',
                ];

                if (Fai.top._openThemeV3) {
                    divHtml = divHtmlV3.join('');
                } else {
                    divHtml = divHtml.join('');
                }
                jm('.loginContent').html(divHtml);
                jm('.sendIcon')
                    .unbind('click')
                    .bind('click', () => {
                        changeHref(Fai.top._openThemeV3 ? hrefV3 : href);
                    });
            } else {
                if (result.notFound) {
                    mobiIng(LS.memberDialogNotFound, true);
                } else {
                    mobiIng(LS.js_argsError, true);
                }
            }
        });
};

const findPwByMailShowNoMail = function () {
    mobiIng(LS.memberShowNoMail);
};

const findPwByMobileShowNoMb = function () {
    mobiIng(LS.memberShowNoMobile);
};

// 邮箱找回密码第二步
const findPwByMailStepTwo = function (macct, Lcid, returnUrl, mail) {
    var profileLoginHeader = Fai.top._openThemeV3 ? 'profileLoginHeader' : '';
    // 主题3.0输入框没有密保码三个字
    var emailFindTipHide = Fai.top._openThemeV3 ? 'emailFindTipHide' : '';
    jm('#memberFdPwdStepOne').parents('.moduleContent').hide();
    var divHtml = [
        '<div class="formMiddleContent moduleContent" id="memberFdPwdStepTwo">',
        '<div class="loginHeader webHeaderBg J_cusBack ' + profileLoginHeader + '">',
        '<a onclick="history.go(0);" class="g_close icon-gClose"></a>',
        '<span class="title pageTitle">',
        LS.memberDialogFwdStepOneTitle,
        '</span>',
        '</div>',
        '<div class="g_globalLine fk-mem-findPw-showMsg"><div class="showMsg">',
        '',
        LS.memberDialogSendedMsg,
        '&nbsp',
        mail,
        '&nbsp',
        LS.memberDialogViewMail,
        '',
        '</div></div>',
        '<div class="loginContent findEmailContent">',
        '<div class="g_globalLine">',
        '<input type="text" maxlength="4"  id="memEmailCode" class="emailStepTwoCode itemEdit g_input mustItem " placeholder="',
        LS.memberDialogPleaseEnterCode,
        '"/>',
        '<input style="display:none;" type="text" id="memMailCodeSign" disabled/>',
        '<span class="icon-pswIcon icon-codeIcon g_mainColor g_main_color_v3 ' + emailFindTipHide + '"></span>',
        '</div>',
        '<div class="g_globalLine">',
        '<input type="button" id="sendEmailCode" style="width:49%;float:left;" class="g_button sendIcon" value="',
        LS.memberDialogReSendMsg,
        '(60)" disabled/>',
        '<input type="button" style="width:49%;float:left;margin-left:2%;" class="g_button sendIcon stepThree" value="',
        LS.memberDialogNextStep,
        '"/>',
        '</div>',
        '</div>',
        '</div>',
    ];

    var divHtmlV3 = [
        '<div class="formMiddleContent moduleContent" id="memberFdPwdStepTwo">',
        '<div class="J_cusBack loginHeader webHeaderBg ' + profileLoginHeader + '">',
        '<a onclick="history.go(0);" class="g_close icon-gClose"></a>',
        '<span class="title pageTitle">',
        LS.memberDialogFwdStepOneTitle,
        '</span>',
        '</div>',
        '<div class="g_globalLine fk-mem-findPw-showMsg"><div class="showMsg">',
        '',
        LS.memberDialogSendedMsg,
        '&nbsp',
        mail,
        '&nbsp',
        LS.memberDialogViewMail,
        '',
        '</div></div>',
        '<div class="loginContent findEmailContent">',
        '<div class="g_globalLine" style="position: relative;">',
        '<input type="text" maxlength="4"  id="memEmailCode" class="emailStepTwoCode itemEdit g_input mustItem " placeholder="',
        LS.memberDialogPleaseEnterCode,
        '"/>',
        '<input style="display:none;" type="text" id="memMailCodeSign" disabled/>',
        '<span class="icon-pswIcon icon-codeIcon g_mainColor g_main_color_v3 ' + emailFindTipHide + '"></span>',
        '<input type="button" id="sendEmailCode" class="g_button sendIcon emailStepTwoSendCode" value="',
        LS.memberDialogReSendMsg,
        '(60)" disabled/>',
        '</div>',
        '<div class="g_globalLine">',
        '<input type="button" class="g_button sendIcon g_main_bgColor_v3 fpw_btn " value="',
        LS.memberDialogNextStep,
        '"/>',
        '</div>',
        '</div>',
        '</div>',
    ];

    // 判断是不是3.0append不同的dom节点
    if (Fai.top._openThemeV3) {
        divHtml = divHtmlV3.join('');
    } else {
        divHtml = divHtml.join('');
    }
    jm('.middleCenter').append(divHtml);
    if (window._openThemeV3) {
        jm('#sendEmailCode')
            .unbind('click')
            .bind('click', () => {
                sendMemberEmailPwdCode(macct);
            });
        jm('.fpw_btn')
            .unbind('click')
            .bind('click', () => {
                memberFdPwdStepThree(macct, Lcid, returnUrl);
            });
    } else {
        jm('#sendEmailCode')
            .unbind('click')
            .bind('click', () => {
                sendMemberEmailPwdCode(macct);
            });
        jm('.stepThree')
            .unbind('click')
            .bind('click', () => {
                memberFdPwdStepThree(macct, Lcid, returnUrl);
            });
    }

    sendMemberEmailCodeImgFix('#memEmailCode', Lcid); //修复密保码的图标位置
    sendMemberEmailPwdCode(macct);
};

// 手机找回密码第二步
const findPwByMobileStepTwo = function (macct, Lcid, returnUrl, mobile, signValue) {
    if (Fai.top._openThemeV3) {
        findPwByMobileStepTwoV3(macct, Lcid, returnUrl, mobile, signValue);
        return;
    }
    jm('#memberFdPwdStepOne').hide();
    jm('#memberFdPwdStepTwo').remove();
    var divHtml = [
        '<div class="formMiddleContent moduleContent" id="memberFdPwdStepTwo">',
        '<div class="J_cusBack loginHeader webHeaderBg">',
        '<a onclick="history.go(0);" class="g_close icon-gClose"></a>',
        '<span class="title pageTitle">',
        LS.memberDialogFwdStepOneTitle,
        '</span>',
        '</div>',
        '<div class="g_globalLine fk-mem-findPw-showMsg"><div class="showMsg">',
        '',
        LS.fdPwdHasSendMobileCode,
        '&nbsp',
        mobile,
        '&nbsp',
        LS.pleaseCheckMobileCode,
        '',
        '</div></div>',
        '<div class="loginContent">',
        '<div class="g_globalLine fk-findPwByMobile">',
        '<input type="text" maxlength="6"  id="memEmailCode" class="itemEdit g_input mustItem" placeholder="',
        LS.inputMobileCode,
        '"/>',
        '<input style="display:none;" type="text" id="memMailCodeSign" value="' + signValue + '" disabled/>',
        '<span class="icon-pswIcon icon-codeIcon g_mainColor g_main_color_v3 456"></span>',
        '</div>',
        '<div class="g_globalLine">',
        '<input type="button" id="sendEmailCode" style="width:49%;float:left;" class="g_button sendIcon" value="',
        LS.memberDialogReSendMsg,
        '(60)" disabled/>',
        '<input type="button" style="width:49%;float:left;margin-left:2%;" class="g_button sendIcon stepThree" value="',
        LS.memberDialogNextStep,
        '"/>',
        '</div>',
        '</div>',
        '</div>',
    ];
    divHtml = divHtml.join('');
    jm('.middleCenter').append(divHtml);
    sendMemberEmailCodeImgFix('#memEmailCode', Lcid); //修复密保码的图标位置
    var val = jm('#sendEmailCode');
    val.unbind('click').bind('click', () => {
        sendFindPwMobileCode(macct, true, Lcid, returnUrl, mobile);
    });
    jm('.stepThree')
        .unbind('click')
        .bind('click', () => {
            memberFdPwdByMobileStepThree(macct, Lcid, returnUrl);
        });
    codeTimeCountDown(val); //验证码倒计时
};

// 手机找回密码第二步  theme3.0
const findPwByMobileStepTwoV3 = function (macct, Lcid, returnUrl, mobile, signValue) {
    jm('#memberFdPwdStepOne').parents('.moduleContent').hide();
    jm('#memberFdPwdStepTwo').remove();
    var divHtml = [
        '<div class="formMiddleContent moduleContent" id="memberFdPwdStepTwo">',
        '<div class="J_cusBack loginHeader webHeaderBg profileLoginHeader">',
        '<a onclick="history.go(0);" class="g_close icon-gClose"></a>',
        '<span class="title pageTitle">',
        LS.memberDialogFwdStepOneTitle,
        '</span>',
        '</div>',
        '<div class="g_globalLine fk-mem-findPw-showMsg"><div class="showMsg">',
        '',
        LS.fdPwdHasSendMobileCode,
        '&nbsp',
        mobile,
        '&nbsp',
        LS.pleaseCheckMobileCode,
        '',
        '</div></div>',
        '<div class="loginContent findEmailContent">',
        '<div class="g_globalLine fk-findPwByMobile">',
        '<input type="text" maxlength="6"  id="memEmailCode" class="itemEdit g_input mustItem emailStepTwoCode" placeholder="',
        LS.inputMobileCode,
        '"/>',
        '<input style="display:none;" type="text" id="memMailCodeSign" value="' + signValue + '" disabled/>',
        '<input type="button" id="sendEmailCode" class="g_button sendIcon emailStepTwoSendCode" value="',
        LS.memberDialogReSendMsg,
        '(60)" disabled/>',
        '</div>',
        '<div class="g_globalLine">',
        '<input type="button" class="g_button sendIcon fpw_btn g_main_bgColor_v3" value="',
        LS.memberDialogNextStep,
        '"/>',
        '</div>',
        '</div>',
        '</div>',
    ];
    divHtml = divHtml.join('');
    jm('.middleCenter').append(divHtml);

    sendMemberEmailCodeImgFix('#memEmailCode', Lcid); //修复密保码的图标位置
    var val = jm('#sendEmailCode');
    val.unbind('click').bind('click', () => {
        sendFindPwMobileCode(macct, true, Lcid, returnUrl, mobile);
    });
    jm('.fpw_btn')
        .unbind('click')
        .bind('click', () => {
            memberFdPwdByMobileStepThree(macct, Lcid, returnUrl);
        });

    codeTimeCountDown(val); //验证码倒计时
};

// 发送找回密码的手机短信
const sendFindPwMobileCode = function (macct, showDetail, Lcid, returnUrl, mobile) {
    sendMemberPwdMobileCode(macct)
        .catch(() => {
            mobiIng(LS.js_systemError, false);
        })
        .then((result) => {
            if (result.success) {
                Fai.top.tmpMobileCodeSgin = result.mobileCodeSign;
                if (showDetail) {
                    findPwByMobileStepTwo(macct, Lcid, returnUrl, mobile, result.mobileCodeSign);
                }
            } else {
                if (result.argErr) {
                    mobiIng(LS.memberDialogSendMobileClose);
                } else if (result.apiKeyNotFound) {
                    mobiIng(LS.memberDialogYunPianErr);
                } else if (result.tplNotFound) {
                    mobiIng(LS.memberDialogMobileTplErr);
                } else if (result.notFound) {
                    mobiIng(LS.memberDialogNotFound);
                } else if (result.limitOne) {
                    mobiIng(LS.getMobileOneMin);
                } else if (result.limitTwo) {
                    mobiIng(LS.getMobileHalfHour);
                } else if (result.noMoney) {
                    mobiIng(LS.memberDialogMobileMoneyErr);
                } else if (result.mobileErr) {
                    mobiIng(LS.memberDialogSendMobileCodeErr);
                } else if (result.sendLimit) {
                    mobiIng(LS.memberDialogSendMobileCodeLimit);
                } else if (result.mobileSysErr) {
                    mobiIng(LS.memberDialogSendMobileSysErr);
                } else if (result.systemErr) {
                    mobiIng(LS.js_systemError);
                } else if (result.tplNationErr) {
                    mobiIng(LS.mobileNationTplErr);
                } else if (result.rt == -301) {
                    mobiIng(LS.smsRanout);
                } else {
                    mobiIng(LS.js_argsError);
                }
            }
        });
};

//除了中文版本即2052用汉字表示(目前就是密保码和新密码那里),其他用图标表示,用图标表示要修正位置。
const sendMemberEmailCodeImgFix = function (dom, Lcid) {
    // 非3.0才需要修正
    if (!Fai.top._openThemeV3) {
        if (Lcid == 2052) {
            jm(dom).css('padding-left', '3.5rem');
        } else {
            jm(dom).css('padding-left', '2rem');
        }
    }
};

// 发送找回密码的邮箱密保
const sendMemberEmailPwdCode = function (memberName) {
    var val = jm('#sendEmailCode');
    codeTimeCountDown(val); //验证码倒计时
    sendMemberPwdEmail(memberName)
        .catch(() => {
            mobiIng(LS.js_systemError, false);
        })
        .then((result) => {
            if (result.success) {
                jm('#memMailCodeSign').val(result.emailCodeSign);
            } else {
                if (result.aliasNotF) {
                    mobiIng(LS.memberDialogAliasNotFound, true);
                } else if (result.notFound) {
                    mobiIng(LS.memberDialogNotFound, true);
                } else if (result.smtpErr) {
                    mobiIng(LS.smtpErr, true);
                } else {
                    mobiIng(LS.js_argsError, true);
                    val.val(LS.memberDialogReSendMsg);
                }
            }
        });
};

// 发送验证码倒计时
const codeTimeCountDown = function (val) {
    var countdown = 60;
    settime(val);
    function settime(val) {
        if (countdown == 0) {
            val.removeAttr('disabled');
            val.val(LS.memberDialogReSendMsg);
            return;
        } else {
            val.attr('disabled', true);
            // 3.0新的文案
            if (Fai.top._openThemeV3) {
                val.val('' + countdown + '' + LS.memberDialogReSendMsgV3 + '');
            } else {
                val.val('' + LS.memberDialogReSendMsg + '(' + countdown + ')');
            }
            countdown--;
        }
        setTimeout(function () {
            settime(val);
        }, 1000);
    }
};

// 手机找回密码第三步
const memberFdPwdByMobileStepThree = function (memberName, Lcid, returnUrl) {
    var memMobileCode = jm('#memEmailCode').val();
    if (!memMobileCode || memMobileCode.length < 0) {
        mobiIng(LS.inputMobileCode);
        return;
    }
    var memMobileCodeSign = jm('#memMailCodeSign').val();
    checkFindPwdByMobileCode({
        memMobileCode,
        memMobileCodeSign,
        memName: memberName,
    })
        .catch(() => {
            mobiIng(LS.js_systemError, false);
        })
        .then((result) => {
            if (typeof result == 'string') {
                result = jm.parseJSON(result);
            }
            if (result.success) {
                memberFdPwdStepThreeSd({
                    memberName,
                    Lcid,
                    returnUrl,
                    memMobileCode,
                    memMobileCodeSign,
                    checkType: 'mobileCode',
                });
            } else if (result.argErr) {
                mobiIng(LS.js_argsError, true);
            } else if (result.notFound) {
                mobiIng(LS.memberDialogNotFound, true);
            } else {
                mobiIng(LS.reGetMobileCode, true);
            }
        });
};

// 邮箱找回密码第三步
const memberFdPwdStepThree = function (memberName, Lcid, returnUrl) {
    var memEmailCode = jm('#memEmailCode').val();
    if (!memEmailCode || memEmailCode.length < 0) {
        mobiIng(LS.memberDialogPleaseEnterCode);
        return;
    }
    var memMailCodeSign = jm('#memMailCodeSign').val();

    checkPwdEmailCode({
        memEmailCode,
        memMailCodeSign,
        memName: memberName,
    })
        .catch(() => {
            mobiIng(LS.js_systemError, false);
        })
        .then((result) => {
            if (result.success) {
                memberFdPwdStepThreeSd({
                    memberName,
                    Lcid,
                    returnUrl,
                    memEmailCode,
                    memMailCodeSign,
                    checkType: 'emailCode',
                });
            } else {
                mobiIng(LS.memberDialogCodeMailFailure, true);
            }
        });
};

// 显示第三步的内容
const memberFdPwdStepThreeSd = function (options) {
    let { Lcid } = options;
    var profileLoginHeader = Fai.top._openThemeV3 ? 'profileLoginHeader' : '';
    var inputV3 = Fai.top._openThemeV3 ? 'inputV3' : '';
    var globalLineRelative = Fai.top._openThemeV3 ? 'globalLineRelative' : '';
    var fpw_btn = Fai.top._openThemeV3 ? ' g_main_bgColor_v3 fpw_btn ' : '';
    var resetpswV3 = Fai.top._openThemeV3 ? ' resetpswV3 ' : '';

    jm('#memberFdPwdStepTwo').hide();
    var divHtml = [
        '<div class="formMiddleContent moduleContent" id="memberFdPwdStepThree">',
        '<div class="J_cusBack loginHeader webHeaderBg ' + profileLoginHeader + '">',
        '<a onclick="history.go(0);" class="g_close icon-gClose"></a>',
        '<span class="title  pageTitle">',
        LS.memberDialogResetPwd,
        '</span>',
        '</div>',
        '<div class="loginContent ' + resetpswV3 + '">',
        '<div class="g_globalLine ' + globalLineRelative + '">',
        '<input type="password" id="memPwd" class="itemEdit g_input mustItem ' + inputV3 + '" placeholder="',
        LS.memberDialogPleaseEnterPwd,
        '"/>',
        '<span class="icon-pswIcon icon-npwdIcon g_mainColor g_main_color_v3"></span>',
        '</div>',
        '<div class="g_globalLine">',
        '<input type="password" id="memPwd2" class="itemEdit g_input mustItem ' + inputV3 + '" placeholder="',
        LS.memberDialogPleaseEnterPwd2,
        '"/>',
        '<span class="icon-pswIcon icon-npwdIcon g_mainColor g_main_color_v3"></span>',
        '</div>',
        '<div class="g_globalLine ' + globalLineRelative + '">',
        '<input type="button" class="g_button sendIcon ' + fpw_btn + '" value="',
        LS.memberDialogConfirm,
        '"/>',
        '</div> ',
        '</div>',
        '</div>',
    ];

    var divHtmlV3 = [
        '<div class="formMiddleContent moduleContent" id="memberFdPwdStepThree">',
        '<div class="J_cusBack loginHeader webHeaderBg ' + profileLoginHeader + '">',
        '<a onclick="history.go(0);" class="g_close icon-gClose"></a>',
        '<span class="title  pageTitle">',
        LS.memberDialogFwdStepOneTitle,
        '</span>',
        '</div>',
        '<div class="loginContent ' + resetpswV3 + '">',
        '<div class="g_globalLine ' + globalLineRelative + '">',
        '<input type="password" id="memPwd" class="itemEdit g_input mustItem ' + inputV3 + '" placeholder="',
        LS.memberDialogPleaseEnterPwd,
        '"/>',
        '<span class="icon-pswIcon icon-npwdIcon g_mainColor g_main_color_v3"></span>',
        '</div>',
        '<div class="g_globalLine">',
        '<input type="password" id="memPwd2" class="itemEdit g_input mustItem ' + inputV3 + '" placeholder="',
        LS.memberDialogPleaseEnterPwd2,
        '"/>',
        '<span class="icon-pswIcon icon-npwdIcon g_mainColor g_main_color_v3"></span>',
        '</div>',
        '<div class="g_globalLine ' + globalLineRelative + '">',
        '<input type="button" class="g_button sendIcon ' + fpw_btn + '" value="',
        LS.memberDialogConfirm,
        '"/>',
        '</div> ',
        '</div>',
        '</div>',
    ];

    if (Fai.top._openThemeV3) {
        divHtml = divHtmlV3.join('');
    } else {
        divHtml = divHtml.join('');
    }
    jm('.middleCenter').append(divHtml);

    jm('.sendIcon')
        .unbind('click')
        .bind('click', () => {
            memberFdPwdStepLast(options);
        });

    sendMemberEmailCodeImgFix('#memPwd', Lcid);
    sendMemberEmailCodeImgFix('#memPwd2', Lcid);
};

// 找回密码最后一步
const memberFdPwdStepLast = function (options) {
    let {
        memberName,
        returnUrl,
        memEmailCode,
        memMailCodeSign,
        memMobileCode,
        memMobileCodeSign,
        checkType = 'mobileCode',
    } = options;
    var profileLoginHeader = Fai.top._openThemeV3 ? 'profileLoginHeader' : '';
    var memPwd = jm('#memPwd').val();
    var memPwd2 = jm('#memPwd2').val();
    if (!memPwd) {
        mobiIng(LS.memberDialogPleaseEnterPwd, true);
        return;
    } else if (!memPwd2) {
        mobiIng(LS.memberDialogPleaseEnterPwd2, true);
        return;
    } else if (memPwd != memPwd2) {
        mobiIng(LS.memberDialogPwdDifToPwd2, true);
        return;
    } else {
        if (memPwd.length < 4 || memPwd.length > 20) {
            mobiIng(jm.format(LS.memberDialogPwdLimit, 4, 20));
            return;
        }
    }

    let mobileCodeCheck = checkType == 'mobileCode';

    let setMemeberPwdFunc = mobileCodeCheck ? setMemberPwdByMobileCode : setMemberPwdByCode;
    let params = {
        memPwd: jm.md5(memPwd),
        memName: memberName,
    };

    if (mobileCodeCheck) {
        params['memMobileCode'] = memMobileCode;
        params['memMobileCodeSign'] = memMobileCodeSign;
    } else {
        params['memEmailCode'] = memEmailCode;
        params['memMailCodeSign'] = memMailCodeSign;
    }

    setMemeberPwdFunc(params)
        .catch(() => {
            mobiIng(LS.js_systemError, false);
        })
        .then((result) => {
            if (result.success) {
                jm('#memberFdPwdStepThree').hide();
                let url = preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp';
                var divHtml = [
                    '<div class="formMiddleContent moduleContent" id="memberFdPwdStepLast">',
                    '<div class="loginContent">',
                    '<div class="g_globalLine" style="text-align: center;">',
                    '<div class="g_circle">',
                    '<span class="icon-lockstarIcon g_mainColor "></span>',
                    '</div>',
                    '<span class="title">',
                    LS.memberDialogPwdforced,
                    '</span>',
                    '</div>',
                    '<div class="g_globalLine" style="margin-top:1.5rem;">',
                    '<input type="button" class="g_button sendIcon" onclick="document.location.href=\'' +
                        returnUrl +
                        '\'" value="',
                    LS.memberDialogHome,
                    '"/>',
                    '</div> ',
                    '</div>',
                    '</div>',
                ];

                var divHtmlV3 = [
                    '<div class="formMiddleContent moduleContent" id="memberFdPwdStepLast">',
                    '<div class="J_cusBack loginHeader webHeaderBg ' + profileLoginHeader + '">',
                    '<a onclick="history.go(0);" class="g_close icon-gClose"></a>',
                    '<span class="title pageTitle">',
                    LS.memberDialogFwdStepOneTitle,
                    '</span>',
                    '</div>',
                    '<div class="loginContent">',
                    '<div class="g_globalLine" style="text-align: center;">',
                    '<div class="g_circle g_main_bdColor_v3">',
                    '<span class="icon-lockstarIcon g_mainColor g_main_color_v3"></span>',
                    '</div>',
                    '<span class="title">',
                    LS.memberDialogPwdforcedV3,
                    '</span>',
                    '</div>',
                    '<div class="g_globalLine" style="margin-top:1.5rem;">',
                    '<input type="button" class="g_button sendIcon  g_main_bgColor_v3 fpw_btn" onclick="document.location.href=\'' +
                        url +
                        '\'" value="',
                    LS.memberDialogLoginInstantly,
                    '"/>',
                    '</div> ',
                    '</div>',
                    '</div>',
                ];

                if (Fai.top._openThemeV3) {
                    divHtml = divHtmlV3.join('');
                } else {
                    divHtml = divHtml.join('');
                }
                jm('.middleCenter').append(divHtml);
            } else {
                if (result.notFound) {
                    mobiIng(LS.memberDialogNotFound, true);
                } else {
                    mobiIng(LS.js_argsError, true);
                }
            }
        });
};
