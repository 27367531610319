<template>
    <div
        v-if="isNewAddModule"
        :class="[
            paramCollectionWrapClass,
            contentBgClass,
            needPaddingWrapClass,
            priceStyle,
        ]"
        :style="paramCollectionWrapStyle"
    >
        <product-name v-if="showProName" :info="info"></product-name>
        <template v-for="(item, index) in list">
            <div
                v-if="isShowPropsExceptPrice(item)"
                :key="`prop_except_price_${index}`"
            >
                <template
                    v-if="
                        !showParamHasEdit ||
                        (showParamHasEdit && module.content.dp.sp) ||
                        (showParamHasEdit && !module.content.dp.sp)
                    "
                >
                    <p
                        :key="'k_' + item.id"
                        class="g_link productParam proParamNoWrap"
                        :class="[classes2, productParamterClass]"
                        :style="productParamter_style"
                    >
                        <template v-if="module.content.dp.sp"
                            >{{ item.name }} :</template
                        >
                        {{ getValue(item) }}
                    </p>
                </template>
                <template v-else-if="_isTwoCol && info[item.fieldKey] == ''">
                    <p
                        :key="'k_' + item.id"
                        class="g_link productParam"
                        :class="[otherPropClass, productParamterClass]"
                        :style="productParamter_style"
                    >
                        <span
                            v-if="
                                module.content.dp.sp &&
                                module.content.proStyle != 3
                            "
                            class="productParamKey"
                            >{{ item.name }} :</span
                        >
                        &nbsp;
                    </p>
                </template>
                <p
                    v-else
                    :key="'k_' + item.id"
                    class="g_link productParam"
                    :class="[
                        otherPropClass,
                        noMarginClass(item),
                        productParamterClass,
                    ]"
                    :style="productParamter_style"
                >
                    <span
                        v-if="
                            module.content.dp.sp && module.content.proStyle != 3
                        "
                        class="productParamKey"
                        >{{ item.name }} :</span
                    >
                    {{ getValue(item) }}
                </p>
            </div>
        </template>
        <div
            v-if="isShowPriceWrap"
            class="priceAndBtnWrap"
            :class="[priceClass, textAlignClass, onlyHasPriceClass]"
        >
            <div
                v-if="!salePromotionOpen"
                class="priceWrap"
                :style="ct3AndPrice3"
            >
                <template v-for="(item, index) in list">
                    <template
                        v-if="item.type == SYS_MALL_PRICE && item.allowed"
                    >
                        <!-- 价格 -->
                        <template v-if="showMallPrice">
                            <p
                                :key="`smp_${index}`"
                                class="f-mallPriceWrap g_link productParam paramPrice"
                                :class="mallPriceWrapClass"
                            >
                                <span
                                    v-if="
                                        module.content.dp.sp &&
                                        module.content.proStyle != 3 &&
                                        !hidePriceName
                                    "
                                    class="productParamKey g_productPrice"
                                    :style="price_style"
                                    >{{ item.name }} :</span
                                >
                                <span
                                    class="g_main_color_v3 mallPrice"
                                    :class="priceClass"
                                    ><span
                                        v-if="!siteCurrencyRight"
                                        class="priceChar g_productPrice"
                                        :style="price_style"
                                        >{{
                                            module.extInfo.choiceCurrencyVal
                                        }}</span
                                    ><span
                                        class="interger g_productPrice"
                                        :style="price_style"
                                        >{{
                                            formateNewPrize(
                                                info['actualPrice'],
                                                true
                                            )
                                        }}<span
                                            class="dot g_productPrice"
                                            :style="price_style"
                                            >.</span
                                        ></span
                                    ><span
                                        class="decimal g_productPrice"
                                        :style="price_style"
                                        >{{
                                            formateNewPrize(
                                                info['actualPrice'],
                                                false
                                            )
                                        }}&nbsp;</span
                                    ><span
                                        v-if="siteCurrencyRight"
                                        class="priceChar g_productPrice"
                                        :style="price_style"
                                        >{{
                                            module.extInfo.choiceCurrencyVal
                                        }}</span
                                    >
                                    </span
                                >
                                <span
                                    v-if="info.vipName && isHorizontalVip"
                                    class="vipName g_bgColor g_main_bgColor_v3"
                                    >{{ info.vipName }}</span
                                >
                            </p>
                        </template>
                    </template>
                    <template
                        v-else-if="
                            (item.type == SYS_MALL_MARKET_PRICE ||
                                item.type == SYS_PRICE) &&
                            item.allowed
                        "
                    >
                        <!-- 市场价 -->
                        <p
                            v-if="showMarketPrice && info[item.fieldKey] != -1"
                            :key="'k_' + item.id"
                            class="f-priceWrap g_link productParam"
                            :class="marketPriceClass"
                        >
                            <span
                                v-if="
                                    module.content.dp.sp &&
                                    module.content.proStyle != 3 &&
                                    !hidePriceName
                                "
                                class="productParamKey g_productMallPrice"
                                :style="mallPrice_style"
                                >{{ item.name }} :</span
                            >
                            <span
                                class="marketPrice g_productMallPrice"
                                :style="mallPrice_style"
                                ><span
                                    v-if="siteCurrencyRight"
                                    class="g_productMallPrice deleteLine"
                                    :style="mallPrice_style"
                                    >{{
                                        formatePrize(info[item.fieldKey])
                                    }}{{ module.extInfo.choiceCurrencyVal
                                    }}&nbsp;</span
                                ><span
                                    v-else
                                    class="g_productMallPrice deleteLine"
                                    :style="mallPrice_style"
                                    >{{ module.extInfo.choiceCurrencyVal
                                    }}{{
                                        formatePrize(info[item.fieldKey])
                                    }}&nbsp;</span
                                ></span
                            >
                        </p>
                    </template>
                </template>
            </div>
            <template v-for="item in list">
                <template
                    v-if="
                        !showSalePromitionPrice &&
                        _extInfo.isShowMarketPrice &&
                        (item.type == SYS_MALL_MARKET_PRICE ||
                            item.type == SYS_PRICE)
                    "
                >
                    <p
                        :key="'k_' + item.id"
                        class="f-priceWrap g_link productParam"
                        :class="marketPriceClass"
                    >
                        <span
                            v-if="
                                module.content.dp.sp &&
                                module.content.proStyle != 3
                            "
                            class="productParamKey g_productMallPrice"
                            :style="mallPrice_style"
                            >{{ item.name }} :</span
                        >
                        <span
                            class="marketPrice g_productMallPrice"
                            :style="mallPrice_style"
                            ><span
                                v-if="siteCurrencyRight"
                                class="g_productMallPrice deleteLine"
                                :style="mallPrice_style"
                                >{{
                                    formatePrize(info[item.fieldKey])
                                }}{{ module.extInfo.choiceCurrencyVal
                                }}&nbsp;</span
                            ><span
                                v-else
                                class="g_productMallPrice deleteLine"
                                :style="mallPrice_style"
                                >{{ module.extInfo.choiceCurrencyVal
                                }}{{
                                    formatePrize(info[item.fieldKey])
                                }}&nbsp;</span
                            ></span
                        >
                    </p>
                </template>
                <div
                    v-if="item.type == SYS_SALEPROMOTIONPRICE"
                    :key="'ss_' + item.id"
                    class="salePromitionWrap"
                >
                    <template
                        v-if="
                            item.type == SYS_SALEPROMOTIONPRICE &&
                            item.allowed &&
                            showSalePromitionPrice
                        "
                    >
                        <div class="salePromition">
                            <!-- 促销价 -->
                            <template v-if="showSalePromitionPrice">
                                <p
                                    :key="'k_' + item.id"
                                    class="f-mallPriceWrap g_link productParam"
                                    :class="mallPriceWrapClass"
                                >
                                    <span
                                        v-if="
                                            module.content.dp.sp &&
                                            module.content.proStyle != 3
                                        "
                                        class="productParamKey g_productPrice"
                                        :style="price_style"
                                        >{{ item.name }} :</span
                                    >
                                    <span
                                        class="g_main_color_v3 mallPrice promitionPrice"
                                        style="font-size: 0"
                                    >
                                        <span
                                            v-if="!siteCurrencyRight"
                                            class="priceChar g_productPrice"
                                            :style="price_style"
                                            >{{
                                                module.extInfo.choiceCurrencyVal
                                            }}</span
                                        >
                                        <span
                                            class="interger g_productPrice"
                                            :style="price_style"
                                            >{{
                                                formateNewPrize(
                                                    info[item.fieldKey],
                                                    true
                                                )
                                            }}</span
                                        >
                                        <span
                                            class="dot g_productPrice"
                                            :style="price_style"
                                            >.</span
                                        >
                                        <span
                                            class="decimal g_productPrice"
                                            :style="price_style"
                                            >{{
                                                formateNewPrize(
                                                    info[item.fieldKey],
                                                    false
                                                )
                                            }}&nbsp;</span
                                        >
                                        <span
                                            v-if="siteCurrencyRight"
                                            class="priceChar g_productPrice"
                                            :style="price_style"
                                            >{{
                                                module.extInfo.choiceCurrencyVal
                                            }}</span
                                        >
                                    </span>
                                </p>
                                <template v-for="item3 in list">
                                    <template
                                        v-if="
                                            _extInfo.isShowMallPrice &&
                                            item3.type == SYS_MALL_PRICE
                                        "
                                    >
                                        <!-- 原价 -->
                                        <p
                                            :key="'kk_' + item3.id"
                                            class="f-priceWrap g_link productParam"
                                            :class="marketPriceClass"
                                        >
                                            <span
                                                v-if="
                                                    module.content.dp.sp &&
                                                    module.content.proStyle != 3
                                                "
                                                class="productParamKey g_productMallPrice"
                                                :style="mallPrice_style"
                                                >{{ item3.name }} :</span
                                            >
                                            <span
                                                class="marketPrice g_productMallPrice"
                                                :style="mallPrice_style"
                                                ><span
                                                    v-if="siteCurrencyRight"
                                                    class="g_productMallPrice deleteLine"
                                                    :style="mallPrice_style"
                                                    >{{
                                                        formatePrize(
                                                            info[item3.fieldKey]
                                                        )
                                                    }}{{
                                                        module.extInfo
                                                            .choiceCurrencyVal
                                                    }}&nbsp;</span
                                                ><span
                                                    v-else
                                                    class="g_productMallPrice deleteLine"
                                                    :style="mallPrice_style"
                                                    >{{
                                                        module.extInfo
                                                            .choiceCurrencyVal
                                                    }}{{
                                                        formatePrize(
                                                            info[item3.fieldKey]
                                                        )
                                                    }}&nbsp;</span
                                                ></span
                                            >
                                        </p>
                                    </template>
                                </template>
                                <template v-for="item2 in list">
                                    <template
                                        v-if="
                                            !showSalePromitionPrice &&
                                            _extInfo.isShowMarketPrice &&
                                            (item2.type ==
                                                SYS_MALL_MARKET_PRICE ||
                                                item2.type == SYS_PRICE)
                                        "
                                    >
                                        <!-- 市场价 -->
                                        <p
                                            :key="'k_' + item2.id"
                                            class="f-priceWrap g_link productParam"
                                            :class="marketPriceClass"
                                        >
                                            <span
                                                v-if="
                                                    module.content.dp.sp &&
                                                    module.content.proStyle != 3
                                                "
                                                class="productParamKey g_productMallPrice"
                                                :style="mallPrice_style"
                                                >{{ item2.name }} :</span
                                            >
                                            <span
                                                class="marketPrice g_productMallPrice"
                                                :style="mallPrice_style"
                                                ><span
                                                    v-if="siteCurrencyRight"
                                                    class="g_productMallPrice deleteLine"
                                                    :style="mallPrice_style"
                                                    >{{
                                                        formatePrize(
                                                            info[item2.fieldKey]
                                                        )
                                                    }}{{
                                                        module.extInfo
                                                            .choiceCurrencyVal
                                                    }}&nbsp;</span
                                                ><span
                                                    v-else
                                                    class="g_productMallPrice deleteLine"
                                                    :style="mallPrice_style"
                                                    >{{
                                                        module.extInfo
                                                            .choiceCurrencyVal
                                                    }}{{
                                                        formatePrize(
                                                            info[item2.fieldKey]
                                                        )
                                                    }}&nbsp;</span
                                                ></span
                                            >
                                        </p>
                                    </template>
                                </template>
                            </template>
                        </div>
                    </template>
                    <mall-cart
                        v-if="showBottomCar"
                        :info="info"
                        :holder="showMallCartHolder"
                    ></mall-cart>
                </div>
            </template>
            <span
                v-if="
                    info.vipName && !isHorizontalVip && extInfo.isShowMallPrice
                "
                class="vipName g_bgColor g_main_bgColor_v3"
                >{{ info.vipName }}</span
            >
            <div
                v-if="module.content.t == 1 && module.content.ct == 3"
                class="cartToRight"
                :style="ct3AndCart3"
            >
                <mall-cart v-if="showMallCartFlag" :info="info"></mall-cart>
            </div>
            <template v-else>
                <mall-cart v-if="showMallCartFlag" :info="info"></mall-cart>
            </template>
        </div>
    </div>
    <div
        v-else-if="!isNewAddModule"
        :class="[paramCollectionWrapClass, needPaddingWrapClass]"
        :style="paramCollectionWrapStyle"
    >
        <product-name v-if="showProName" :info="info"></product-name>
        <mall-cart
            v-if="showTopMallCart"
            :info="info"
            :holder="showMallCartHolder"
        ></mall-cart>
        <template v-for="(item, i) in list">
            <template v-if="item.type == SYS_MALL_PRICE && item.allowed">
                <!-- 价格 -->
                <template v-if="showMallPrice && !salePromotionOpen">
                    <p
                        v-if="
                            !(extInfo.isShowPromotionPrice && salePromotionOpen)
                        "
                        :key="`smp_${i}`"
                        class="paramLine"
                    ></p>
                    <p
                        :key="`smpp_${i}`"
                        class="f-mallPriceWrap g_link productParam paramPrice g_productParamter"
                        :class="mallPriceWrapClass"
                        :style="productParamter_style"
                    >
                        <span
                            v-if="
                                module.content.dp.sp &&
                                module.content.proStyle != 3 &&
                                !style2
                            "
                            class="productParamKey"
                            >{{
                                module.extInfo.bookingOpen
                                    ? ''
                                    : item.name + '：'
                            }}</span
                        >
                        <span v-if="siteCurrencyRight" class="g_main_color_v3" :class="priceClass"
                            >{{ formatePrize(info['actualPrice']) }}{{ module.extInfo.choiceCurrencyVal}}</span
                        >
                        <span v-else class="g_main_color_v3" :class="priceClass"
                            >{{ module.extInfo.choiceCurrencyVal
                            }}{{ formatePrize(info['actualPrice']) }}</span
                        >
                        <span
                            v-if="info.vipName"
                            class="vipName g_bgColor g_main_bgColor_v3"
                            >{{ info.vipName }}</span
                        >
                        <mall-cart
                            v-if="showMallCart.mCart && !salePromotionOpen"
                            :info="info"
                        ></mall-cart>
                    </p>
                </template>
            </template>
            <template
                v-else-if="item.type == SYS_MALL_MARKET_PRICE && item.allowed"
            >
                <!-- 市场价 -->
                <p
                    v-if="showMarketPrice && info[item.fieldKey] != -1"
                    :key="'k_' + item.id"
                    class="g_link productParam g_productParamter"
                    :class="marketPriceClass"
                    :style="productParamter_style"
                >
                    <span
                        v-if="
                            module.content.dp.sp &&
                            module.content.proStyle != 3 &&
                            !style2
                        "
                        class="productParamKey"
                        >{{ item.name }}：</span
                    >
                    <span v-if="siteCurrencyRight" class="marketPrice"
                        >{{ formatePrize(info[item.fieldKey]) }}{{ module.extInfo.choiceCurrencyVal}}</span
                    >
                    <span v-else class="marketPrice"
                        >{{ module.extInfo.choiceCurrencyVal
                        }}{{ formatePrize(info[item.fieldKey]) }}</span
                    >
                </p>
            </template>
            <template
                v-else-if="item.type == SYS_SALEPROMOTIONPRICE && item.allowed"
            >
                <!-- 促销价 -->
                <p
                    v-if="showSalePromitionPrice && style2 && !isNewAddModule"
                    :key="`ss1_${i}`"
                    class="paramLine"
                ></p>
                <p
                    v-if="showSalePromitionPrice"
                    :key="'k_' + item.id"
                    class="f-mallPriceWrap g_link productParam g_productParamter"
                    :class="mallPriceWrapClass"
                    :style="productParamter_style"
                >
                    <span
                        v-if="
                            module.content.dp.sp &&
                            module.content.proStyle != 3 &&
                            !style2
                        "
                        class="productParamKey"
                        >{{
                            module.extInfo.bookingOpen ? '' : item.name + '：'
                        }}</span
                    >
                    <span v-if="siteCurrencyRight" class="g_main_color_v3 mallPrice"
                        >{{ formatePrize(info[item.fieldKey]) }}{{ module.extInfo.choiceCurrencyVal}}</span
                    >
                    <span v-else  class="g_main_color_v3 mallPrice"
                        >{{ module.extInfo.choiceCurrencyVal
                        }}{{ formatePrize(info[item.fieldKey]) }}</span
                    >
                    <mall-cart
                        v-if="showMallCart.mCart"
                        :info="info"
                    ></mall-cart>
                </p>
                <template v-for="(item2, i) in list">
                    <template
                        v-if="
                            _extInfo.isShowMarketPrice &&
                            (item2.type == SYS_MALL_MARKET_PRICE ||
                                item2.type == SYS_PRICE)
                        "
                    >
                        <!-- 划线市场价 -->
                        <p
                            :key="`showmark_${i}`"
                            class="g_link productParam g_productParamter"
                            :class="marketPriceClass"
                            :style="productParamter_style"
                        >
                            <span
                                v-if="
                                    module.content.dp.sp &&
                                    module.content.proStyle != 3 &&
                                    !style2
                                "
                                class="productParamKey"
                                >{{ item2.name }}：</span
                            >
                            <span v-if="siteCurrencyRight" class="marketPrice"
                                >{{ formatePrize(info[item2.fieldKey]) }}{{ module.extInfo.choiceCurrencyVal
                                }}</span
                            >
                            <span v-else class="marketPrice"
                                >{{ module.extInfo.choiceCurrencyVal
                                }}{{ formatePrize(info[item2.fieldKey]) }}</span
                            >
                        </p>
                    </template>
                </template>
                <template v-for="(item3, i) in list">
                    <template
                        v-if="
                            _extInfo.isShowMallPrice &&
                            !_extInfo.isShowMarketPrice &&
                            item3.type == SYS_MALL_PRICE
                        "
                    >
                        <!-- 划线商城价 -->
                        <p
                            :key="`showmllp_${i}`"
                            class="g_link productParam g_productParamter"
                            :class="marketPriceClass"
                            :style="productParamter_style"
                        >
                            <span
                                v-if="
                                    module.content.dp.sp &&
                                    module.content.proStyle != 3 &&
                                    !style2
                                "
                                class="productParamKey"
                                >{{ item3.name }}：</span
                            >
                            <span v-if="siteCurrencyRight" class="marketPrice"
                                >{{ formatePrize(info[item3.fieldKey]) }}{{ module.extInfo.choiceCurrencyVal}}</span
                            >
                            <span v-else class="marketPrice"
                                >{{ module.extInfo.choiceCurrencyVal
                                }}{{ formatePrize(info[item3.fieldKey]) }}</span
                            >
                        </p>
                    </template>
                </template>
            </template>
            <template
                v-else-if="
                    style2 &&
                    (!showParamHasEdit ||
                        (showParamHasEdit && module.content.dp.sp) ||
                        (showParamHasEdit && !module.content.dp.sp))
                "
            >
                <p
                    :key="'k_' + item.id"
                    class="g_link productParam proParamNoWrap g_productParamter"
                    :class="classes2"
                    :style="productParamter_style"
                >
                    <template v-if="module.content.dp.sp"
                        >{{ item.name }}：</template
                    >
                    {{ getValue(item) }}
                </p>
            </template>
            <template v-else-if="_isTwoCol && info[item.fieldKey] == ''">
                <p
                    :key="'k_' + item.id"
                    class="g_link productParam g_productParamter"
                    :class="otherPropClass"
                    :style="productParamter_style"
                >
                    <span
                        v-if="
                            module.content.dp.sp &&
                            module.content.proStyle != 3 &&
                            !style2
                        "
                        class="productParamKey"
                        >{{ item.name }}：</span
                    >
                    &nbsp;
                </p>
            </template>
            <p
                v-else
                :key="'k_' + item.id"
                class="g_link productParam g_productParamter"
                :class="otherPropClass"
                :style="productParamter_style"
            >
                <span
                    v-if="
                        module.content.dp.sp &&
                        module.content.proStyle != 3 &&
                        !style2
                    "
                    class="productParamKey"
                    >{{ item.name }}：</span
                >
                {{ getValue(item) }}
            </p>
        </template>
    </div>
</template>
<script>
import { mapFlag } from '@/shared/flag';
import productItemMixin from '../../mixins/productItemMixin';
import mallCart from './mall-cart.vue';
import productName from './product-name.vue';
// 抽离该组件的原因：1、每个样式这个组件输出的位置不同 2、复用

const manageMode = VITE_APP_MODE !== 'visitor';

const SYS_MALL_PRICE = 11; // 商城价(实际交易价)
const SYS_MALL_MARKET_PRICE = 12; // 市场价
const SYS_SALEPROMOTIONPRICE = -1; // 促销价
const SYS_PRICE = 2; // 废弃的市场价

export default {
    name: 'PropPanel',
    components: { mallCart, productName },
    mixins: [productItemMixin],
    inject: ['module', 'options'],
    props: [
        'info',
        'extInfo',
        'list',
        'showMallCart',
        'textAlignClass',
        'noNameWrap',
    ],
    data() {
        return {
            SYS_MALL_PRICE, // 商城价(实际交易价)
            SYS_MALL_MARKET_PRICE, // 市场价
            SYS_SALEPROMOTIONPRICE, // 促销价
            SYS_PRICE, // 废弃的市场价
            manageMode,
        };
    },
    computed: {
        ...mapFlag({
            showParamHasEdit: 0x2,
            isNewAddModule: 0x4,
        }),
        isShowParamCollectionWrap() {
            let isShowProp = false;
            this.list.map((item) => {
                if (
                    this.module.content.proStyle != 3 &&
                    item.type != this.SYS_MALL_PRICE &&
                    item.type != this.SYS_MALL_MARKET_PRICE &&
                    item.type != this.SYS_SALEPROMOTIONPRICE &&
                    item.type != this.SYS_PRICE
                ) {
                    isShowProp = true;
                }
            });
            return (
                this.showProName ||
                this.isShowPriceWrap ||
                this.showMallCartFlag ||
                isShowProp
            );
        },
        isShowPriceWrap() {
            let isOpenMallPrice = this.showMallPrice;
            let isShowMallPrice = false;

            let isOpenMarkerPrice = this.showMarketPrice;
            let isShowMarkerPrice = false;

            let isOpenSalePromitionPrice = this.showSalePromitionPrice;
            let isShowSalePromitionPrice = false;

            this.list.forEach((item) => {
                if (item.type == this.SYS_MALL_PRICE && item.allowed) {
                    isShowMallPrice = true;
                }
                if (
                    item.type == this.SYS_MALL_MARKET_PRICE ||
                    (item.type == this.SYS_PRICE && item.allowed)
                ) {
                    isShowMarkerPrice = true;
                }
                if (item.type == this.SYS_SALEPROMOTIONPRICE && item.allowed) {
                    isShowSalePromitionPrice = true;
                }
            });
            // 价格开启
            isShowMallPrice = isShowMallPrice && isOpenMallPrice;
            // 市场价开启
            isShowMarkerPrice = isOpenMarkerPrice && isShowMarkerPrice;
            // 促销价开启
            isShowSalePromitionPrice = isOpenSalePromitionPrice; // 促销价不应该依赖市场价。@ken 20200318 ID1027584

            return (
                isShowMallPrice ||
                isShowMarkerPrice ||
                isShowSalePromitionPrice ||
                this.showMallCartFlag
            ) && !this.isLinkProduct;
        },
        type() {
            return this.module.content.t;
        },
        colmumType: function () {
            return this.module.content.ct;
        },
        _isBigCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 1;
            } else {
                return this.module.content.t == '33';
            }
        },
        _isTwoCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 2;
            } else {
                return this.module.content.t == '1';
            }
        },
        _isThreeCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 3;
            } else {
                return this.module.content.t == '31';
            }
        },
        style2() {
            return ['8', '9', '7'].includes(this.module.content.t);
        },
        otherPropClass() {
            let classes = '';

            if (this.module.content.dp.sp) {
                classes += ` ${this.noNameWrap} `;
            }

            return `${classes} ${this.textAlignClass}`;
        },
        needPaddingWrapClass() {
            if (this._isBigCol) {
                return '';
            } else {
                if (this.isShowParamCollectionWrap) {
                    return 'needPaddingWrap';
                } else {
                    return '';
                }
            }
        },
        priceStyle() {
            return this.module.content.priceStyle == 0
                ? 'horizontalPrice'
                : 'verticalPrice';
        },
        mallPrice_style() {
            var style = '';
            if (!manageMode) {
                return style;
            }
            if (this.isNewAddModule) {
                var newProductMallPriceStyle = this.module.content.mhighs.pmp;
                if (newProductMallPriceStyle.type == 1) {
                    var fontWeight =
                        newProductMallPriceStyle.nlfb == 1 ? 'bold' : 'normal';
                    var fontStyle =
                        newProductMallPriceStyle.nlfi == 1
                            ? 'italic'
                            : 'normal';
                    var textDecoration =
                        newProductMallPriceStyle.lfd == 1
                            ? 'underline'
                            : 'none';
                    style +=
                        'font-size:' + newProductMallPriceStyle.lfs + 'rem;';
                    style +=
                        'line-height:' + newProductMallPriceStyle.nlflh + 'px;';
                    style += 'font-weight:' + fontWeight + ';';
                    style += 'text-decoration:' + textDecoration + ';';
                    style += 'font-style:' + fontStyle + ';';
                    style += 'color:' + newProductMallPriceStyle.lfc + ';';
                }
            }
            return style;
        },
        price_style() {
            var style = '';
            if (!manageMode) {
                return style;
            }
            if (this.isNewAddModule) {
                var newProductPriceStyle = this.module.content.mhighs.pprice;
                if (newProductPriceStyle.type == 1) {
                    var fontWeight =
                        newProductPriceStyle.nlfb == 1 ? 'bold' : 'normal';
                    var fontStyle =
                        newProductPriceStyle.nlfi == 1 ? 'italic' : 'normal';
                    var textDecoration =
                        newProductPriceStyle.lfd == 1 ? 'underline' : 'none';
                    style += 'font-size:' + newProductPriceStyle.lfs + 'rem;';
                    style +=
                        'line-height:' + newProductPriceStyle.nlflh + 'px;';
                    style += 'font-weight:' + fontWeight + ';';
                    style += 'text-decoration:' + textDecoration + ';';
                    style += 'font-style:' + fontStyle + ';';
                    style += 'color:' + newProductPriceStyle.lfc + ';';
                }
            }
            return style;
        },
        mallPriceWrapClass() {
            let class1 =
                this.module.content.ta == 0 && this.showMallCart.mCart
                    ? 'f-mallPriceStyle'
                    : '';

            if (this.module.content.dp.sp) {
                class1 += ` ${this.noNameWrap} `;
            }

            if (
                this.module.content.t == '1' &&
                this.module.content.ct == 3 &&
                this.info.vipName
            ) {
                class1 += ' limitWidth';
            }

            return `${class1} ${this.textAlignClass}`;
        },
        ct3AndCart3() {
            let priceLen = 0;
            let actual_price = this.info['actualPrice'];
            const showProName = this.module.content.dp.sp;
            if (actual_price != undefined) {
                let pl = parseInt(actual_price);
                priceLen = pl.toString().length;
            }
            let style = {};
            if (
                this.module.content.ct == '3' &&
                !showProName &&
                priceLen <= 3
            ) {
                // ct == 3 表示三列排列，且如果价格长度<=3，且不显示参数名，则需要去除width:100%实现购物车按钮并排
                style['width'] = '0.95rem';
                // return "width:0.95rem";
            } else {
                //价格长度过长 恢复购物车按钮width:100%
                style['width'] = '100%';
                // return "width:100%";
            }

            style['width'] = '100%';
            style['justify-content'] = 'flex-start';
            style['margin-top'] = '0.2rem';

            return style;
        },
        ct3AndPrice3() {
            let priceLen = 0;
            let actual_price = this.info['actualPrice'];
            const showProName = this.module.content.dp.sp;
            if (actual_price != undefined) {
                let pl = parseInt(actual_price);
                priceLen = pl.toString().length;
            }
            if (
                this.module.content.ct == '3' &&
                !showProName &&
                priceLen <= 3 &&
                this.showMallCartFlag
            ) {
                return 'max-width: calc(100% - 0.95rem);';
            } else {
                return 'max-width: 100%;';
            }
        },
        marketPriceClass() {
            let classes = '';

            if (this.module.content.dp.sp) {
                classes += ` ${this.noNameWrap} `;
            }

            if (this.style2) {
                classes += ' proParamNoWrap ';
            }

            return `${classes} ${this.textAlignClass}`;
        },
        salePromotionOpen() {
            //是否有促销价
            return (
                this.info.extInfo.salePromotionOpen &&
                this.info.extInfo.salePromotionParam.saleType != 2
            );
        },
        showMallPrice() {
            return !(!this.extInfo.isShowMarketPrice && this.salePromotionOpen);
        },
        showMarketPrice() {
            return !(this.extInfo.isShowMallPrice && this.salePromotionOpen);
        },
        showSalePromitionPrice() {
            return !(
                this.extInfo.isShowMarketPrice &&
                !this.extInfo.isShowMallPrice &&
                this.salePromotionOpen
            );
        },
        paramCollectionWrapClass() {
            const type = this.module.content.t;
            let classes = '';
            if (this._isThreeCol || this._isTwoCol) {
                if (this.module.content.picSetting.picSizeSet != 1) {
                    classes = 'paramCollectionWrap';
                }
            }

            if (type == 7 || type == 8 || type == 9) {
                classes += ' paramPadding ';
            }

            if (type != 32 && !this._isBigCol) {
                classes += ' paramCollection ';
            }

            if(this.module.content.proParamWrap === 1) {
                classes += 'productParamWrap '
            }

            return classes;
        },
        contentBgClass() {
            if (
                !this._isBigCol &&
                this.module.content.t != '3' &&
                this.module.content.t != '32' &&
                this.module.content.proStyle != 11 &&
                this.module.content.proStyle != 3 &&
                this.module.content.proStyle != 7
            ) {
                return 'contentBgClass';
            }
            return '';
        },
        paramCollectionWrapStyle() {
            if (
                this.isNewAddModule &&
                this.module.content.t != 3 &&
                this.module.content.t != 32 &&
                !this._isBigCol
            ) {
                return {
                    ...this.paramCollectionWrapStyle2,
                    ...this._contentBgStyle,
                };
            } else {
                return this.paramCollectionWrapStyle2;
            }
        },
        paramCollectionWrapStyle2() {
            let tmpStyle = {};
            if (this._isTwoCol || this._isThreeCol) {
                if (this.module.content.picSetting.picSizeSet == 1) {
                    tmpStyle.overflow = 'hidden';
                    tmpStyle.textOverflow = 'ellipsis';
                }
            }
            return tmpStyle;
        },
        productParamter_style() {
            var style = '';
            if (!manageMode) {
                return style;
            }
            if (this.isNewAddModule) {
                var newProductParamStyle = this.module.content.mhighs.npp;
                if (newProductParamStyle.type == 1) {
                    var fontWeight =
                        newProductParamStyle.nlfb == 1 ? 'bold' : 'normal';
                    var fontStyle =
                        newProductParamStyle.nlfi == 1 ? 'italic' : 'normal';
                    var textDecoration =
                        newProductParamStyle.lfd == 1 ? 'underline' : 'none';
                    style += 'font-size:' + newProductParamStyle.lfs + 'rem;';
                    style +=
                        'line-height:' + newProductParamStyle.nlflh + 'px;';
                    style += 'font-weight:' + fontWeight + ';';
                    style += 'text-decoration:' + textDecoration + ';';
                    style += 'font-style:' + fontStyle + ';';
                    style += 'color:' + newProductParamStyle.lfc + ';';
                }
            } else {
                var productParamterStyle = this.module.content.mhighs.pp;
                if (productParamterStyle.type == 1) {
                    if (productParamterStyle.lfs) {
                        style +=
                            'font-size:' + productParamterStyle.lfs + 'rem;';
                    } else {
                        style += 'font-size:12px;';
                    }
                    if (productParamterStyle.lfct == 1) {
                        style += 'color:' + productParamterStyle.lfc + ';';
                    }
                    if (productParamterStyle.lfd == 1) {
                        style += 'text-decoration:underline;';
                    } else {
                        style += 'text-decoration:none;';
                    }
                }
            }
            return style;
        },
        showProName() {
            const type = this.module.content.t;
            return (
                this.module.content.showProName &&
                (this._isTwoCol ||
                    this._isThreeCol ||
                    type == '7' ||
                    type == '8' ||
                    type == '9')
            );
        },
        classes2() {
            return `${this.noNameWrap} ${this.textAlignClass}`;
        },
        productParamterClass() {
            return this.isNewAddModule
                ? ' g_newProductParam '
                : ' g_productParamter ';
        },
        showTopMallCart() {
            const content = this.module.content;

            return (
                (this._isTwoCol ||
                    this._isThreeCol ||
                    content.t == 7 ||
                    content.t == 8 ||
                    content.t == 9 ||
                    content.t == 32) &&
                this.showMallCart.mallCart2 &&
                content.picSetting.picSizeSet == 0
            );
        },
        showMallCartHolder() {
            return (
                this.showMallCart.mallCart2 &&
                ['8', '9', '32'].includes(this.module.content.t)
            );
        },
        priceClass() {
            var priceClass = 'noPriceClass';
            this.module.content.dp.pp.forEach(function (item) {
                if (item.id == 11 || item.id == 12 || item.id == 2) {
                    priceClass = 'hasPriceClass';
                }
            });
            return priceClass;
        },
        onlyHasPriceClass() {
            var priceClass = 'onlyHasPriceClass';
            this.module.content.dp.pp.forEach(function (item) {
                if (item.id != 11 && item.id != 12 && item.id != 2) {
                    priceClass = '';
                }
            });

            return priceClass;
        },
        showMallCartFlag() {
            var simpleFlag =
                this._isBigCol && this.module.content.proStyle == 3
                    ? false
                    : true;
            return (
                this.showMallCart.mCart && !this.salePromotionOpen && simpleFlag
            );
        },
        showBottomCar() {
            var simpleFlag =
                this._isBigCol && this.module.content.proStyle == 3
                    ? false
                    : true;
            return this.showMallCart.mCart && simpleFlag;
        },
        showMallPriceCum: function () {
            var mallPrize = this.module.content.dp.pp.find(function (item) {
                return item.id == 12 || item.id == 2;
            });
            if (!!mallPrize && mallPrize.show) {
                return this.isNewAddModule;
            }
            return false;
        },
        showPriceCum: function () {
            var mallPrize = this.module.content.dp.pp.find(function (item) {
                return item.id == 11;
            });
            if (!!mallPrize && mallPrize.show) {
                return this.isNewAddModule;
            }
            return false;
        },
        showPriceStyle: function () {
            return this.showMallPriceCum && this.showPriceCum;
        },
        hidePriceName: function () {
            return this.showPriceStyle && this.module.content.priceStyle == 0;
        },
        hasMarketPrice: function () {
            var flag = false;
            for (var item of this.list) {
                if (item.type == this.SYS_MALL_MARKET_PRICE && item.allowed) {
                    flag = true;
                    break;
                }
            }
            return flag;
        },
        isHorizontalVip: function () {
            var flag = false;
            //ct：列数  priceStyle：左右/上下排列   t：模块样式类型
            if (
                [1].includes(this.module.content.ct) ||
                this.module.content.priceStyle == 1 ||
                ['3', '32'].includes(this.module.content.t) ||
                (this.module.content.ct == 3 && this.module.content.t == 1)
            ) {
                flag = true;
            }
            return flag;
        },

        siteCurrencyRight() {
            return this.module.extInfo.siteCurrencyRight && this.module.extInfo.choiceCurrencyVal != '￥'
        }
    },
    methods: {
        getValue(item) {
            let value = this.info[item.fieldKey];
            if (item.type === 16 && item.unit) {
                value += item.unit;
            }
            return value;
        },
        isShowPropsExceptPrice(item) {
            // module.content.proStyle != 3 && item.type != SYS_MALL_PRICE && item.type != SYS_MALL_MARKET_PRICE && item.type != SYS_SALEPROMOTIONPRICE && item.type != SYS_PRICE
            const priceArr = [
                SYS_MALL_PRICE,
                SYS_MALL_MARKET_PRICE,
                SYS_SALEPROMOTIONPRICE,
                SYS_PRICE,
            ];
            return (
                this.module.content.proStyle != 3 &&
                !priceArr.includes(item.type)
            );
        },
        noMarginClass(item) {
            return (!this.module.content.dp.sp ||
                (this._isBigCol && this.module.content.proStyle == 3)) &&
                this.info[item.fieldKey] == ''
                ? 'noMarginCLass'
                : '';
        },
        formatePrize(num) {
            //输入int类型数字后自动在后面加.00，输入double型保留小数点后两位
            num += '';
            num = num.replace(/[^0-9|.]/g, '');

            //if(/^0+/) num = num.replace(/^0+/, '');
            num = num.replace(/^0+/, '');
            if (!/\./.test(num)) num += '.00';
            if (/^\./.test(num)) num = '0' + num;
            num += '00';
            num = num.match(/\d+\.\d{2}/)[0];
            return num;
        },
        formateNewPrize(num, needInteger) {
            var integer = 0;
            var decimal = 0;
            //输入int类型数字后自动在后面加.00，输入double型保留小数点后两位
            num += '';
            num = num.replace(/[^0-9|.]/g, '');

            //if(/^0+/) num = num.replace(/^0+/, '');
            num = num.replace(/^0+/, '');
            if (!/\./.test(num)) num += '.00';
            if (/^\./.test(num)) num = '0' + num;
            num += '00';
            num = num.match(/\d+\.\d{2}/)[0];

            if (num.includes('.')) {
                try {
                    var arr = num.split('.');
                    integer = arr[0];
                    decimal = arr[1];
                } catch (e) {
                    console.log(e);
                }
            }
            return needInteger ? integer : decimal;
        },
    },
};
</script>
<style>
.isNewAddModule .mProductList .marketPrice {
    font-size: 0.45rem;
    line-height: normal;
}
.isNewAddModule .mProductList .marketPrice .deleteLine {
    text-decoration: line-through !important;
    white-space: normal;
    word-break: break-all;
    color: #9c9c9c;
}
.isNewAddModule .mProductList .mallPrice {
    font-size: 0.6rem;
    line-height: normal;
    word-break: break-all;
    white-space: nowrap;
}
.isNewAddModule .mProductList .f-mallCart svg {
    width: 0.95rem;
    height: 0.95rem;
    fill: white;
    color: transparent;
}
.isNewAddModule .mProductList .priceAndBtnWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.isNewAddModule .mProductList .priceAndBtnWrap.noPriceClass {
    justify-content: flex-start;
    padding-top: 0.3rem;
}
.isNewAddModule .mProductList .verticalPrice .textAlign_center .priceWrap {
    width: 100%;
}
.isNewAddModule .mProductList .priceWrap {
    overflow: hidden;
}
.isNewAddModule .mProductList .priceWrap .productParam:nth-child(1) {
    margin-top: 0;
}

.isNewAddModule .mProductList .horizontalPrice .textAlign_center .salePromition,
.isNewAddModule .mProductList .horizontalPrice .textAlign_center .priceWrap {
    justify-content: center;
    margin-right: 0;
    width: 100%;
}
.isNewAddModule
    .mProductList
    .horizontalPrice
    .textAlign_center
    .salePromition {
    margin-bottom: 0.2rem;
}
.isNewAddModule .mProductList .horizontalPrice .textAlign_left .priceWrap {
    justify-content: flex-start;
    margin-right: 0;
    /* width: 100%; */
    /* min-width: 4.91rem; */
    max-width: calc(100% - 0.95rem);
}
.isNewAddModule
    .mProductList
    .horizontalPrice
    .textAlign_center
    .priceWrap
    .f-mallPriceWrap {
    margin-right: 0;
    max-width: 100%;
}
.form
    .isNewAddModule
    .mProductList
    .verticalPrice
    .textAlign_center
    .productParam.paramPrice,
.form
    .isNewAddModule
    .mProductList
    .verticalPrice
    .textAlign_center
    .productParam.f-priceWrap {
    text-align: center;
}
.isNewAddModule .mProductList .horizontalPrice .priceWrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.2rem;
    margin-right: 0.2rem;
}
.form .isNewAddModule .mProductList .horizontalPrice .f-mallPriceWrap {
    margin-right: 0.35rem;
    margin-top: 0;
    margin-bottom: 0;
    word-break: break-all;
    white-space: normal;
}
.form .isNewAddModule .mProductList .verticalPrice .f-mallPriceWrap {
    margin-bottom: 0;
    white-space: normal;
}
.form .isNewAddModule .mProductList .horizontalPrice .limitWidth,
.form .isNewAddModule .mProductList .verticalPrice .limitWidth {
    width: 2.99rem;
}
.form .isNewAddModule .mProductList .horizontalPrice .f-priceWrap {
    margin-top: 0;
    margin-bottom: 0;
    word-break: break-all;
    white-space: normal;
}
.isNewAddModule .mProductList .priceChar,
.isNewAddModule .mProductList .decimal,
.isNewAddModule .mProductList .dot {
    font-size: 0.5rem;
    font-weight: bold;
}
.isNewAddModule .mProductList .interger {
    font-size: 0.65rem;
    font-weight: bold;
}

.isNewAddModule .mProductTileForm .paramCollection {
    margin-top: 0.4rem;
}

.isNewAddModule .mProductList .priceAndBtnWrap.textAlign_center {
    justify-content: center;
}
.isNewAddModule
    .mProductList
    .jz_productScr
    .textAlign_center
    .proListBookingBtn {
    margin-left: 0.5rem;
    top: 0;
}
.form .isNewAddModule .mProductList .productParam.noMarginCLass {
    margin: 0;
}
.form .isNewAddModule .mProductList .productParam.paramPrice {
    white-space: normal;
}
.form .isNewAddModule .mProductList .promitionPrice {
    text-decoration: none !important;
}
.form .isNewAddModule .mProductList .salePromitionWrap {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}
.form .isNewAddModule .mProductList .textAlign_center .salePromitionWrap {
    justify-content: center;
}
.form .isNewAddModule .mProductList .onlyHasPriceClass {
    padding-top: 0.3rem;
}
.priceAndBtnWrap .vipName {
    display: inline-block;
    border-radius: 0.1rem;
    font-size: 0.45rem;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 0 0.15rem;
}
.f-mallPriceWrap .vipName {
    display: inline-block;
    border-radius: 0.1rem;
    font-size: 0.45rem;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 0 0.15rem;
}
.priceAndBtnWrap .cartToRight {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.priceAndBtnWrap.textAlign_center .cartToRight {
    justify-content: center;
}

.form .mProductList.mProductList .productParamWrap .productParam {
    overflow: initial;
    text-overflow: initial;
    white-space: initial;
    word-break: break-word;
}
</style>
